$colred: #F33131;
$colblue: #6ACCF9;
$colyell: #FED176;
$colgreen: #66E69A;
$coltxt: #393939;
$colspan: #243976;

.raytme {
   width: 100%;
   height: 250px;
   background: none;
   position: relative;
   float: left;
   @media (max-device-width: 900px) {
      height: 460px;
   } 
   @media (max-device-width: 780px) {
      height: 420px;
   } 
}
   .raytme-center {
      width: 1180px;
      min-width: 1180px;
      height: 100%;
      margin: auto;
      background: none;
      position: relative;
      margin-top: -20px;
      @media (max-device-width: 1180px) {
         width: 900px;
         min-width: 900px;
         margin-top: -40px;
      } 
      @media (max-device-width: 900px) {
         width: 780px;
         min-width: 780px;
      } 
      @media (max-device-width: 780px) {
         width: 480px;
         min-width: 480px;
      } 
   }

// - -  стиль для блока
      .raytme-block {
         width: 200px;
         height: 250px;
         background: none;
         float: left;
         margin-left: 40px;
         &.part-01 {
            margin-left: 130px;
            @media (max-device-width: 1180px) {
               margin-left: 10px;
            } 
            @media (max-device-width: 900px) {
               margin-left: 160px;
            } 
            @media (max-device-width: 780px) {
               margin-left: 30px;
            } 
         }
         @media (max-device-width: 1180px) {
            margin-left: 10px;
         } 
         @media (max-device-width: 900px) {
            margin-left: 40px;
         } 
         @media (max-device-width: 780px) {
            margin-left: 20px;
         } 
         &.part-03 {
            @media (max-device-width: 900px) {
               margin-left: 160px;
               margin-top: -40px;
            } 
            @media (max-device-width: 780px) {
               margin-left: 30px;
            } 
         }
         &.part-04 {
            @media (max-device-width: 900px) {
               margin-top: -40px;
            } 
         }
      }
         .raytme-block-img {
            width: 120px;
            height: 120px;
            background: none;
            margin-left: 40px;
            margin-top: 25px;
            float: left;
         }
         .raytme-block-txt {
            width: 200px;
            height: 50px;
            background: none;
            margin-top: 20px;
            float: left;
            & p {
               font-size: 1.1em;
               color: $colspan;
               letter-spacing: 1px;
               line-height: 20px;
               font-weight: 600;
            }
         }