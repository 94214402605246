$colred: #F33131;
$colblue: #6ACCF9;
$colyell: #FED176;
$colgreen: #66E69A;
$coltxt: #393939;
$colspan: #243976;

.primermid {
   width: 100%;
   height: 1460px;
   float: left;
   position: relative;
   background: none;
   margin-bottom: 40px;
   @media (max-device-width: 1180px) {
      height: 1260px;
      margin-bottom: 10px;
   }
   @media (max-device-width: 900px) {
      margin-top: 50px;
   }
   @media (max-device-width: 780px) {
      height: 1000px;
      margin-bottom: 20px;
   }
   
}
.primermid-center {
   width: 1180px;
   min-width: 1180px;
   height: 100%;
   margin: auto;
   background: none;
   position: relative;
   @media (max-device-width: 1180px) {
      width: 780px;
      min-width: 780px;
   }
   @media (max-device-width: 780px) {
      width: 480px;
      min-width: 480px;
   }
}

   .primermid-big {
      width: 100%;
      height: 500px;
      background: none;
      float: left;
      border-radius: 20px;
      & img {
         height: 100%;
         border-radius: 20px;
      }
      cursor: pointer;
      @media (max-device-width: 1180px) {
         height: 400px;
      }
      @media (max-device-width: 780px) {
         height: 300px;
      }
   }



   .primermid-min {
      width: 100%;
      height: 400px;
      background: none;
      float: left;
      position: relative;
      margin-top: 20px;
      cursor: pointer;
      @media (max-device-width: 1180px) {
         height: 320px;
      }
      @media (max-device-width: 780px) {
         height: 250px;
      }
         // - - - - вертушка
         & .swiper {
            width: 100%;
            height: 400px;
            position: relative;
            float: left;
            @media (max-device-width: 1180px) {
               height: 320px;
            }
            @media (max-device-width: 780px) {
               height: 250px;
            }
         }
           & .swiper-slide {
               text-align: center;
               background: #fff;
               display: flex;
               justify-content: center;
               align-items: center;
            }      
             & .swiper-slide img {
                  display: block;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  // margin-left: 5%;
                  border-radius: 20px;
               }
         & .swiper-slide {
            width: 45%;
            @media (max-device-width: 780px) {
               width: 55%;
            }
         }
         & .swiper-slide:nth-child(1n) {
            width: 45%;
            @media (max-device-width: 780px) {
               width: 55%;
            }
         }
         & .swiper-slide:nth-child(2n) {
            width: 45%;
            @media (max-device-width: 780px) {
               width: 55%;
            }
         }
         & .swiper-slide:nth-child(3n) {
            width: 45%;
            @media (max-device-width: 780px) {
               width: 55%;
            }
         }
         & .swiper-button-prev {
            background: none !important;
         }
         & .swiper-button-next {
            background: none !important;
         }
   }
   

   .primermid-col {
      width: 100%;
      height: 250px;
      background: $colblue;
      float: left;
      position: relative;
      margin-top: -870px;
      @media (max-device-width: 1180px) {
         margin-top: -800px;
         height: 230px;
      }
      @media (max-device-width: 780px) {
        margin-top: -650px;
        height: 200px;
      }
   }

   .primermid-fon {
      width: 100%;
      height: 450px;
      background: url(../img/fon_03.jpg);
      background-origin: content-box;
      background-size: cover;
      background-position: center;
      position: relative;
      float: left;
      margin-top: -500px;
      @media (max-device-width: 1180px) {
         margin-top: -480px;
         height: 400px;
      }
      @media (max-device-width: 780px) {
        margin-top: -400px;
        height: 350px;
      }
   }

   .primermid-fon-img {
      width: 250px;
      height: 250px;
      float: right;
      position: relative;
      margin-right: 15%;
      background: none;
      margin-top: 250px;
      @media (max-device-width: 1600px) {
         margin-right: 12%;
       }
       @media (max-device-width: 1400px) {
         margin-right: 10%;
       }
       @media (max-device-width: 1180px) {
         margin-right: 6%;
         margin-top: 200px;
       }
       @media (max-device-width: 780px) {
         margin-right: 2%;
         margin-top: 130px;
       }
   }


      .primermid-saitfor {
         width: 90%;
         height: 1050px;
         background: #6ACCF9;
         position: absolute;
         top:  0px;
         margin-left: 5%;
         z-index: 8888;
         animation-name: titleAnimation4;
         animation-timing-function:ease;
         animation-duration: 1s;
         -webkit-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
         -moz-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
         box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
         border-radius: 20px;
         @media (max-device-width: 1180px) {
            height: 700px;
         }
         @media (max-device-width: 780px) {
            height: 450px;
         }
         & .swiper {
            width: 100%;
            height: 100%;
            position: relative;
            float: left;
         }
            & .swiper-slide {
               text-align: center;
               background: #fff;
               display: flex;
               justify-content: center;
               align-items: center;
            }      
              & .swiper-slide img {
                  display: block;
                  width: 90%;
                  height: auto;
                  object-fit: cover;
                  border-radius: 20px;
               }
      }
               //анимация движения вылетающей статьи
               @keyframes titleAnimation4 {
                  0% {
                     transform: translateY(400px);
                     opacity: 0;
                  }
                  100% {
                        transform: translateY(0px);
                        opacity: 1;
                     
                  }
               }

               .primermid-saitfor-img {
                  width: 40px;
                  height: 40px;
                  position: absolute;
                  top: 10px;
                  right: 10px;
                  z-index: 9999;
                  background: none;
                  transition: 0.2s;
                  & img {
                     width: 100%;
                     height: 100%;
                     background: none;
                  }
                  opacity: 0.7;
                  &:hover {
                     opacity: 1;
                     cursor: pointer;
                     transition: 0.2s;
                  }
               }

      .im-1180 {
         display: block !important;
         @media (max-device-width: 1180px) {
            display: none !important;
         }
         @media (max-device-width: 780px) {
            display: none !important;
         }
      }

      .im-780 {
         display: none !important;
         @media (max-device-width: 1180px) {
            display: block !important;
         }
         @media (max-device-width: 780px) {
            display: none !important;
         }
      }

      .im-480 {
         display: none !important;
         @media (max-device-width: 1180px) {
            display: none !important;
         }
         @media (max-device-width: 780px) {
            display: block !important;
         }
      }