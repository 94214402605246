$colred: #F33131;
$colblue: #6ACCF9;
$colyell: #FED176;
$colgreen: #66E69A;
$coltxt: #393939;
$colspan: #243976;

.blogopis {
   width: 100%;
   height: 200px;
   background: none;
   position: relative;
   float: left;
   margin-bottom: 40px;
   // @media (max-device-width: 1280px) {
   //    height: 80px;
   // } 
   @media (max-device-width: 900px) {
      height: 230px;
   } 
}
   .blogopis-center{
      width: 1280px;
      height: 100%;
      margin: auto;
      background: none;
      position: relative;
      @media (max-device-width: 1280px) {
         width: 1180px;
         min-width: 1180px;
      } 
      @media (max-device-width: 1180px) {
         width: 1080px;
         min-width: 1080px;
      } 
      @media (max-device-width: 1080px) {
         width: 900px;
         min-width: 900px;
      } 
      @media (max-device-width: 900px) {
         width: 780px;
         min-width: 780px;
      } 
      @media (max-device-width: 780px) {
         width: 480px;
         min-width: 480px;
      } 
   }

   .blogopis-center-left {
      width: 170px;
      height: 150px;
      background: none;
      margin-top: 50px;
      float: left;
      position: relative;
      margin-left: 200px;
      @media (max-device-width: 1280px) {
         margin-left: 100px;
      } 
      @media (max-device-width: 1080px) {
         margin-left: 20px;
      } 
      @media (max-device-width: 1080px) {
         margin-left: 20px;
      } 
      @media (max-device-width: 780px) {
         margin-left: -50px;
      } 
   }
   
   .blogopis-center-right {
      width: 600px;
      height: 200px;
      background: none;
      float: right;
      position: relative;
      margin-right: 200px;
      @media (max-device-width: 1280px) {
         margin-right: 100px;
      } 
      @media (max-device-width: 1080px) {
         margin-right: 20px;
         width: 600px;
      } 
      @media (max-device-width: 900px) {
         width: 500px;
      } 
      @media (max-device-width: 780px) {
         margin-right: 10px;
         width: 320px;
         height: 230px;
      } 
      .blogopis-center-right-txt {
         width: 100%;
         height: 120px;
         background: none;
         float: left;
         position: relative;
         margin-top: 20px;
         & p{
            text-align: left;
            font-size: 1.1em;
         }
         @media (max-device-width: 780px) {
            margin-top: 0px;
            height: 180px;
         } 
      }
      .blogopis-center-right-but{
         width: 50%;
         height: 40px;
         background: $colblue;
         float: right;
         position: relative;
         border-radius: 10px;
         cursor: pointer;
         @media (max-device-width: 900px) {
            width: 80%;
            margin-right: 10%;
         } 
         & p{
            width: 100%;
            font-size: 1.2em;
            color: #fff;
            line-height: 40px;
            transition: 0.3s;
            &:hover{
               letter-spacing: 1.5px;
               transition: 0.3s;
            }
         }
      }
   }

   