$colred: #F33131;
$colblue: #6ACCF9;
$colyell: #FED176;
$colgreen: #66E69A;
$coltxt: #393939;
$colspan: #243976;

.primerdow {
   width: 100%;
   height: 600px;
   float: left;
   position: relative;
   background: none;
   margin-bottom: 40px;
   @media (max-device-width: 1180px) {
      height: 500px;
      margin-bottom: 10px;
   }
   @media (max-device-width: 780px) {
      height: 400px;
      margin-bottom: 20px;
   }
   
}
.primerdow-center {
   width: 1180px;
   min-width: 1180px;
   height: 100%;
   margin: auto;
   background: none;
   position: relative;
   @media (max-device-width: 1180px) {
      width: 780px;
      min-width: 780px;
   }
   @media (max-device-width: 780px) {
      width: 480px;
      min-width: 480px;
   }
}

// - - - стиль для кнопки КАК СДЕЛАТЬ ЗАКАЗ?
   .primerdow-center-span {
      width: 100%;
      height: 50px;
      background: none;
      float: left;
      margin-bottom: 30px;
   }
      .primerdow-center-span-txt {
         width: 400px;
         height: 40px;
         margin: auto;
         background: $colblue;
         border-radius: 20px;
         cursor: pointer;
         transition: 0.3s;
         -webkit-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.3);
         -moz-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.3);
         box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.3);
         & p {
            width: 100%;
            text-align: center;
            font-size: 1.3em;
            line-height: 40px;
            color: #fff;
            letter-spacing: 1px;
            transition: 0.3s;
         }
         &:hover {
            width: 400px;
            transition: 0.3s;
            & p {
               letter-spacing: 3px;
               transition: 0.3s;
            }
            -webkit-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
            -moz-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
            box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
         }
         &:active {
            -webkit-box-shadow:2px 3px 5px 0px rgba(0,0,0,0.0);
            -moz-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
            box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.0);
         }
      }


// стиль для примера большого сайта
   .primerdow-big {
      width: 100%;
      height: 500px;
      background: none;
      float: left;
      border-radius: 20px;
      & img {
         height: 100%;
         border-radius: 20px;
      }
      cursor: pointer;
      @media (max-device-width: 1180px) {
         height: 400px;
      }
      @media (max-device-width: 780px) {
         height: 300px;
      }
   }


      .primerdow-saitfor {
         width: 90%;
         height: 1050px;
         background: #6ACCF9;
         position: absolute;
         top:  0px;
         margin-left: 5%;
         z-index: 8888;
         animation-name: titleAnimation4;
         animation-timing-function:ease;
         animation-duration: 1s;
         -webkit-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
         -moz-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
         box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
         border-radius: 20px;
         @media (max-device-width: 1180px) {
            height: 700px;
         }
         @media (max-device-width: 780px) {
            height: 450px;
         }
         & .swiper {
            width: 100%;
            height: 100%;
            position: relative;
            float: left;
         }
            & .swiper-slide {
               text-align: center;
               background: #fff;
               display: flex;
               justify-content: center;
               align-items: center;
            }      
              & .swiper-slide img {
                  display: block;
                  width: 90%;
                  height: auto;
                  object-fit: cover;
                  border-radius: 20px;
               }
      }
               //анимация движения вылетающей статьи
               @keyframes titleAnimation4 {
                  0% {
                     transform: translateY(400px);
                     opacity: 0;
                  }
                  100% {
                        transform: translateY(0px);
                        opacity: 1;
                     
                  }
               }

               .primerdow-saitfor-img {
                  width: 40px;
                  height: 40px;
                  position: absolute;
                  top: 10px;
                  right: 10px;
                  z-index: 9999;
                  background: none;
                  transition: 0.2s;
                  & img {
                     width: 100%;
                     height: 100%;
                     background: none;
                  }
                  opacity: 0.7;
                  &:hover {
                     opacity: 1;
                     cursor: pointer;
                     transition: 0.2s;
                  }
               }

      .im-1180 {
         display: block !important;
         @media (max-device-width: 1180px) {
            display: none !important;
         }
         @media (max-device-width: 780px) {
            display: none !important;
         }
      }

      .im-780 {
         display: none !important;
         @media (max-device-width: 1180px) {
            display: block !important;
         }
         @media (max-device-width: 780px) {
            display: none !important;
         }
      }

      .im-480 {
         display: none !important;
         @media (max-device-width: 1180px) {
            display: none !important;
         }
         @media (max-device-width: 780px) {
            display: block !important;
         }
      }