@charset "utf-8";

*{				
	margin: 0;
	padding: 0;
	outline: none;
	// background: red;
	width: 100%;
   background: #ffffff; 
   // background: -moz-linear-gradient(-45deg,  #ffffff 0%, #f3f3f3 32%, #ededed 67%, #ffffff 100%); 
   // background: -webkit-linear-gradient(-45deg,  #ffffff 0%,#f3f3f3 32%,#ededed 67%,#ffffff 100%); 
   // background: linear-gradient(135deg,  #ffffff 0%,#f3f3f3 32%,#ededed 67%,#ffffff 100%); 
   // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); 
}
   p, span, li {
      float: left;
      width: auto;
      font-family: 'Nunito', sans-serif;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0px;
      text-align: center;
      text-decoration: none;	
      background: none;
      color: #483e3e;
   }	
   img {
      width: 100%;
      height: auto;
      float: left;
      background: none;
   }

   .image {
      width: 100%;
      height: auto;
      float: left;
      background: none;
   }

   html {
      scroll-behavior: smooth;
      width: 100%;
      min-width: 480px;
   }	

   #root {
      background: none;
      width: 100%;
   }

   .main {
      background: none;
      width: 100%;
   }

   body {
      background: none;
      width: 100%;
   }