$colred: #F33131;
$colblue: #6ACCF9;
$colyell: #FED176;
$colgreen: #66E69A;
$coltxt: #393939;
$colspan: #243976;

.banersoc {
   width: 100%;
   height: 540px;
   background: url(../img/fon_01.jpg);
   background-origin: content-box;
   background-size: cover;
   background-position: center;
   position: relative;
   float: left;
   margin-bottom: 30px;
}
   .banersoc-center {
      width: 1280px;
      min-width: 1280px;
      height: 100%;
      margin: auto;
      background: none;
      @media (max-device-width: 1280px) {
         width: 1180px;
         min-width: 1180px;
      } 
      @media (max-device-width: 1180px) {
         width: 1080px;
         min-width: 1080px;
      } 
      @media (max-device-width: 1080px) {
         width: 900px;
         min-width: 900px;
      } 
      @media (max-device-width: 900px) {
         width: 780px;
         min-width: 780px;
      } 
      @media (max-device-width: 780px) {
         width: 480px;
         min-width: 480px;
      } 
   }

// - - - блок с телефоном и соцсетями
   .banersoc-mob {
      width: 400px;
      height: 500px;
      margin-left: 150px;
      margin-top: 30px;
      background: none;
      float: left;
      position: relative;
      @media (max-device-width: 1180px) {
         margin-left: 130px;
      } 
      @media (max-device-width: 1080px) {
         margin-left: 30px;
      } 
   }


   // - - - верхний блок с телефоном и почтой
      .banersoc-mob-top {
         width: 400px;
         height: 200px;
         background: none;
         float: left;
      }
      // блок с телефоном
         .banersoc-mob-top-phone {
            width: 300px;
            height: 40px;
            margin-left: 10px;
            background: none;
            float: left;
            & p {
               font-size: 1.3em;
               line-height: 40px;
               text-align: left;
               font-weight: 400;
               color: $colspan;
               letter-spacing: 1px;
               transition: 0.3s;
               &:hover {
                  letter-spacing: 2px;
                  transition: 0.3s;
               }
               @media (max-device-width: 780px) {
                  display: none;
               } 
            }
         } 

         // блок с мессенджерами
         .banersoc-mob-top-soc {
            width: 350px;
            height: 40px;
            margin-top: 5px;
            float: left;
            background: none;
         }
            .top-soc-mobile {
               width: 40px;
               height: 40px;
               border-radius: 20px;
               background: $colred;
               float: left;
               & img {
                  width: 70%;
                  height: 70%;
                  margin-left: 15%;
                  margin-top: 15%;
               }
            }
            .top-soc-social {
               width: 260px;
               height: 40px;
               margin-left: 20px;
               background: none;
               float: left;
               @media (max-device-width: 900px) {
                  display: none;
               } 
            }
               .top-soc-social-img {
                  width: 40px;
                  height: 40px;
                  background: none;
                  float: left;
                  margin-left: 5px;
                  & img {
                     width: 80%;
                     height: 80%;
                     margin-top: 10%;
                     margin-left: 10%;
                     transition: 0.2s;
                     &:hover {
                        width: 90%;
                        height: 90%;
                        margin-left: 5%;
                        margin-top: 5%;
                        transition: 0.2s;
                     }
                  }
               }

         // блок с почтой
         .banersoc-mob-top-mail {
            width: 350px;
            height: 40px;
            margin-top: 5px;
            float: left;
            background: none;
         }
            .top-mail-img {
               width: 40px;
               height: 40px;
               border-radius: 20px;
               background: $colblue;
               float: left;
               & img {
                  width: 70%;
                  height: 70%;
                  margin-left: 15%;
                  margin-top: 15%;
               }
            }
            .top-mail-txt{
               width: 260px;
               height: 40px;
               margin-left: 30px;
               background: none;
               float: left;
               @media (max-device-width: 900px) {
                  display: none;
               } 
               & p {
                  font-size: 1.1em;
                  color: $colspan;
                  line-height: 40px;
                  font-weight: 400;
                  letter-spacing: 1px;
                  text-align: left;
                  transition: 0.2s;
                  &:hover {
                     letter-spacing: 1.5px;
                     transition: 0.2s;
                     margin-left: -4px;
                  }
               }
            }
      
 // - - - - блок с социальными сетями
      .banersoc-mob-down {
         width: 350px;
         height: 270px;
         background: none;
         float: left;
         margin-top: 100px;
         position: relative;
      }
      .banersoc-mob-down-soc {
         width: 350px;
         height: 60px;
         background: none;
         float: left;
         margin-top: 0px;
      }
         .banersoc-mob-down-soc-img {
            width: 45px;
            height: 45px;
            background: none;
            float: left;
         }
         .banersoc-mob-down-soc-txt {
            width: 250px;
            height: 45px;
            float: left;
            background: none;
            margin-left: 10px;
            @media (max-device-width: 900px) {
               display: none;
            } 
            & p {
               font-size: 1.2em;
               color: $colspan;
               letter-spacing: 1px;
               line-height: 45px;
               background: none;
               transition: 0.2s;
               &:hover {
                  letter-spacing: 1.5px;
                  transition: 0.2s;
               }
            }
         }

// - - - - - стили для планшета

         .plan-menu {
            display: block;
            background: $colyell;
            border-radius: 10px;
            & .top-soc-social-img {
               margin-left: 10px;
            }
            animation-name: titleAnimationPlan;
            animation-timing-function:ease;
            animation-duration: 1s;
         }
         .top-mail-txt.plan-menu {
            margin-left: 20px;
            & p {
               margin-left: 20px;
            }
         }
         .banersoc-mob-down-soc-txt.plan-menu {
            margin-left: 20px;
            & p {
               margin-left: 5px;
            }
         }
         .banersoc-mob-top-phone.plan-menu {
            background: none;
            border-radius: none;
            margin-left: 70px;
            & p {
               display: block;
            }
         }




         //анимация движения вылетающей статьи
         @keyframes titleAnimationPlan {
            0% {
               transform: translateX(-20px);
               opacity: 0.5;
               -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
               clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
            }
            100% {
                  transform: translateX(0px);
                  opacity: 1;
                  -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 100%);
                  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            }
         }