$colred: #F33131;
$colblue: #6ACCF9;
$colyell: #FED176;
$colgreen: #66E69A;
$coltxt: #393939;
$colspan: #243976;

.dopuslug {
   width: 100%;
   height: 430px;
   background: none;
   position: relative;
   float: left;
   @media (max-device-width: 1180px) {
      height: 730px;
   } 
   @media (max-device-width: 780px) {
      height: 1240px;
   } 
}
   .dopuslug-center {
      width: 1180px;
      min-width: 1180px;
      height: 100%;
      margin: auto;
      background: none;
      position: relative;
      margin-top: -20px;
      @media (max-device-width: 1180px) {
         width: 780px;
         min-width: 780px;
      } 
      @media (max-device-width: 780px) {
         width: 480px;
         min-width: 480px;
      } 
   }

      .dopuslug-block {
         width: 300px;
         height: 400px;
         background: #fff;
         -webkit-box-shadow: 3px 5px 5px 0px rgba(0,0,0,0.3);
         -moz-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.3);
         box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.3);
         margin-left: 40px;
         float: left;
         position: relative;
         animation-name: titleAnimationDop;
         animation-timing-function: ease;
         animation-duration: 10s;
         animation-iteration-count: infinite;
         @media (max-device-width: 1180px) {
            animation-name: titleAnimationDop1180;
         } 
         &.blopart-01 {
            margin-left: 100px;
            animation-delay: 0.3s;
            @media (max-device-width: 1180px) {
               margin-left: 20px;
               z-index: 2;
            }   
            @media (max-device-width: 780px) {
               margin-left: 80px;
            }    
         }
         
         &.blopart-02 {
            animation-delay: 0.5s;
            @media (max-device-width: 1180px) {
               margin-left: -80px;
               margin-top: 300px;
               z-index: 1;
            }    
            @media (max-device-width: 780px) {
               margin-left: 80px;
               margin-top: 10px;
            }   
         }
         &.blopart-03 {
            animation-delay: 0.7s;
            @media (max-device-width: 1180px) {
               margin-left: -80px;
               z-index: 2;
            }   
            @media (max-device-width: 780px) {
               margin-left: 80px;
               margin-top: 10px;
            }    
         }
      }
         .dopuslug-block-img {
            width: 300px;
            height: 200px;
            background: none;
            float: left;
         }
         .dopuslug-block-txt {
            width: 300px;
            height: 180px;
            background: none;
            float: left;
            & p {
               font-size: 1.5em;
               color: $colspan;
               font-weight: 600;
               letter-spacing: 1px;
               line-height: 30px;
               width: 80%;
               margin-left: 10%;
               font-family: 'Comfortaa', sans-serif;
               margin-top: 10px;
               margin-bottom: 20px;
            }
            & ul {
               width: 80%;
               margin-left: 15%;
               margin-top: 10px;
               background: none;
            }
            & li{
               text-align: left;
               width: 100%;
            }
         }

         @keyframes titleAnimationDop {
            0% {
               transform: translateX(-200px);
               opacity: 0;
            }
            25% {
                  transform: translateX(0px);
                  opacity: 1;
            }
            75% {
               transform: translateX(0px);
               opacity: 1;
            }  
            100% {
               transform: translateX(200px);
               opacity: 0;
            }
         }
         
         @keyframes titleAnimationDop1180 {
            0% {
               transform: translateX(-200px);
               opacity: 0;
            }
            25% {
                  transform: translateX(0px);
                  opacity: 1;
            }
            75% {
               transform: translateX(0px);
               opacity: 1;
            }  
            100% {
               transform: translateY(200px);
               opacity: 0;
            }
         } 