$colred: #F33131;
$colblue: #6ACCF9;
$colyell: #FED176;
$colgreen: #66E69A;
$coltxt: #393939;
$colspan: #243976;

.blogspisok {
   width: 100%;
   height: auto;
   position: relative;
   float: left;
   margin-bottom: 30px;
}
   .blogspisok-center {
      width: 1280px;
      height: 100%;
      margin: auto;
      background: none;
      position: relative;
      display: grid;
      // grid-template: repeat(3, 400px) / repeat(3, 400px);
      grid-template-columns: 400px 400px 400px;
      justify-content: space-around;
      row-gap: 20px;
      margin-bottom: 30px;
      @media (max-device-width: 1280px) {
         width: 1180px;
         min-width: 1180px;
         grid-template-columns: 400px 400px;
      } 
      @media (max-device-width: 1180px) {
         width: 1080px;
         min-width: 1080px;
      } 
      @media (max-device-width: 1080px) {
         width: 900px;
         min-width: 900px;
      } 
      @media (max-device-width: 900px) {
         width: 780px;
         min-width: 780px;
         grid-template-columns: 400px;
      } 
      @media (max-device-width: 780px) {
         width: 480px;
         min-width: 480px;
      } 

      .blogspisok-statya {
         width: 100%;
         height: 100%;
         border: 1px dashed #393939;
         border-radius: 10px;
         // -webkit-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
         // -moz-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
         // box-shadow: -2px 3px 5px 0px rgba(0,0,0,0.2);
         transition: 0.2s;
         &:hover {
            -webkit-box-shadow: -2px -3px 5px 0px rgba(0,0,0,0.2), 2px 3px 5px 0px rgba(0,0,0,0.2);;
            -moz-box-shadow: -2px -3px 5px 0px rgba(0,0,0,0.2), 2px 3px 5px 0px rgba(0,0,0,0.2);;
            box-shadow: -2px -3px 5px 0px rgba(0,0,0,0.2), 2px 3px 5px 0px rgba(0,0,0,0.2);;
            transition: 0.2s;
         }
         & .blogspisok-statya-img {
            width: 370px;
            height: 250px;
            margin: auto;
            margin-top: 10px;
            background: none;
            & img{
               height: 100%;
               border-radius: 6px;
            }
         }
         & .blogspisok-statya-text {
            width: 370px;
            height: 120px;
            margin: auto;
            margin-top: 10px;
            background: none;
            .statya-text-span {
               width: 100%;
               height: 30px;
               background: none;
               float: left;
               & p{
                  font-weight: 600;
                  font-size: 1.1em;
               }
            }
            .statya-text-opis {
               width: 100%;
               height: 60px;
               background: none;
               float: left;
               & p{
                  font-weight: 300;
                  font-size: 1em;
                  text-align: left;
               }
            }
            .statya-text-snoska {
               width: 100%;
               height: 30px;
               background: none;
               float: left;
               .statya-text-snoska-hesh {
                  width: 60%;
                  height: 100%;
                  background: none;
                  float: left;
                  margin-top: 2px;
                  & p{
                     font-weight: 300;
                     font-size: 0.9em;
                     text-align: left;
                     color: $colspan;
                  }
               }
               .statya-text-snoska-ssil {
                  width: 38%;
                  height: 35px;
                  background: none;
                  float: left;
                  // border: 1px dashed $coltxt;
                  border-radius: 10px;
                  margin-top: -7px;
                  cursor: pointer;
                  transition: 0.2s all;
                  &:hover {
                     background: $colgreen;
                     & p {
                        color: #fff;
                        transition: 0.1s all;
                     }
                     transition: 0.2s all;
                  }
                  & p{
                     line-height: 35px;
                     width: 100%;
                  }
               }
            }
         }
      }
   }
         // пагинация
         .blogspisok-pagin {
            width: 100%;
            height: 35px;
            position: relative;
            display: flex;
            grid-template-columns: 50px;
            column-gap: 10px;
            float: left;
            justify-content: center; 
            .blogspisok-pagin-round {
               width: 30px;
               height: 30px;
               border: 1px dashed black;
               border-radius: 15px;
               cursor: pointer;
               & p {
                  line-height: 30px;
                  width: 100%;
               }
               &:hover {
                  background: $colgreen;
                  border-radius: 15px; 
                  border: 1px solid #fff;
                  & p{
                     color: #fff;
                  }
               }
            }
            .active {
               background: $colgreen;
               border: 1px solid #fff;
               & p{
                  color: #fff;
               }
            }

         }