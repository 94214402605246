$colred: #F33131;
$colblue: #6ACCF9;
$colyell: #FED176;
$colgreen: #66E69A;
$coltxt: #393939;
$colspan: #243976;

.formzaya {
   width: 100%;
   height: 280px;
   background: none;
   position: relative;
   float: left;
   @media (max-device-width: 900px) {
      height: 360px;
   } 
   @media (max-device-width: 780px) {
      height: 400px;
   } 
}
   .formzaya-center {
      width: 1180px;
      min-width: 1180px;
      height: 100%;
      margin: auto;
      background: none;
      position: relative;
      margin-top: -20px;
      @media (max-device-width: 1180px) {
         width: 1080px;
         min-width: 1080px;
      } 
      @media (max-device-width: 1080px) {
         width: 900px;
         min-width: 900px;
      } 
      @media (max-device-width: 900px) {
         width: 780px;
         min-width: 780px;
      } 
      @media (max-device-width: 780px) {
         width: 480px;
         min-width: 480px;
      } 
   }

      .formzaya-block {
         width: 870px;
         height: 250px;
         background: none;
         border: 1px dotted $colspan;
         border-radius: 20px;
         float: left;
         position: relative;
         margin-left: 170px;
         @media (max-device-width: 1180px) {
            margin-left: 100px;
         } 
         @media (max-device-width: 1080px) {
            margin-left: 10px;
         } 
         @media (max-device-width: 900px) {
            width: 760px;
            height: 350px;
         } 
         @media (max-device-width: 780px) {
            width: 460px;
            height: 408px;
         } 
      }


// - - - - - форма для воода данных
         .formzaya-block-left {
            width: 400px;
            height: 210px;
            float: left;
            background: none;
            margin-top: 20px;
            margin-left: 20px;
            & input {
               border-radius: 20px;
               width: 370px;
               height: 30px;
               border: 1px solid silver;
               padding: 5px;
               float: left;
               clear: both;
               margin-left: 10px;
               margin-top: 20px;
               background-color: #ECECEC;
               font-size: 1.1em;
               letter-spacing: 1px;
               color:rgb(71, 71, 71);
               text-indent: 10px;
               transition: 0.1s;
               -webkit-box-shadow: 3px 5px 5px 0px rgba(0,0,0,0.3);
               -moz-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.3);
               box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.2);
               &:hover {
                  background-color: #fff;
                  transition: 0.1s;
               }
               &:focus {
                  background-color: #fff;
                  -webkit-box-shadow: inset 3px 5px 5px 0px rgba(0,0,0,0.3);
                  -moz-box-shadow: inset 3px 3px 5px 0px rgba(0,0,0,0.3);
                  box-shadow: inset 1px 1px 4px 0px rgba(0,0,0,0.2); 
                  transition: 0.1s;               
               }
               &.phone-error{
                  color: red;
               }
            }
            @media (max-device-width: 900px) {
               margin-left: 170px;
               margin-top: 55px;
            } 
            @media (max-device-width: 780px) {
               margin-left: 30px;
            } 
         }



// - - - - - форма для инфо и выбора соцсетей
         .formzaya-block-right {
            width: 400px;
            height: 230px;
            float: left;
            margin-left: 20px;
            margin-top: 20px;
            background: none;
            @media (max-device-width: 900px) {
               height: 100px;
               margin-top: 0px;
               width: 700px;
            } 
            @media (max-device-width: 780px) {
               width: 440px;
               margin-left: 10px;
            } 
         }
            .formzaya-block-right-txt {
               width: 100%;
               height: 50px;
               margin-top: 5px;
               float: left;
               background: none;
               & p {
                  font-size: 1em;
                  text-align: right;
                  font-style: oblique;
                  @media (max-device-width: 780px) {
                     text-align: center;
                  } 
               }
               @media (max-device-width: 900px) {
                  margin-top: -250px;
                  margin-left: 300px;
                  width: 400px;
               } 
               @media (max-device-width: 780px) {
                  margin-left: 20px;
               } 
            }
            .formzaya-block-right-span {
               width: 100%;
               height: 50px;
               margin-top: 20px;
               float: left;
               background: none;
               @media (max-device-width: 900px) {
                  width: 300px;
               } 
               & p {
                  width: 70%;
                  margin-left: 30%;
                  font-size: 1.1em;
                  text-align: right;
                  color: $colspan;
                  font-weight: 500;
                  @media (max-device-width: 900px) {
                     width: 90%;
                     margin-left: 5%;
                     text-align: left;
                  } 
               }
               @media (max-device-width: 900px) {
                  margin-top: 0px;
                  margin-left: 20px;
               } 
            }

            // форма для выбора дступной соц.сети
            .formzaya-block-right-soc {
               width: 70%;
               height: 50px;
               margin-top: 10px;
               margin-left: 30%;
               float: left;
               background: none;
               & input {
                  visibility: hidden; 
                  position: absolute; 
                  width: auto;
               }
               @media (max-device-width: 900px) {
                  margin-top: 5px;
                  margin-left: 0px;
                  width: 300px;
                  float: right;
               } 
               @media (max-device-width: 780px) {
                  margin-right: 60px;
                  margin-top: 10px;
               } 
            }
               .right-soc-img {
                  width: 40px;
                  height: 40px;
                  float: left;
                  background: none;
                  margin-left: 15px;
                  cursor: pointer;
                  border-radius: 10px;
                  transition: 0.2s;
                  & img {
                     border-radius: 20px;
                     transition: 0.2s;
                  }
               }
               .change-soc {
                  transition: 0.1s;
                  border: 1px dotted rgb(0, 47, 255);
                  margin-left: 14px;
                  margin-top: -1px;
                  transition: 0.2s;
                  & img {
                     width: 91%;
                     margin-top: 4%;
                     margin-left: 4%;
                     transition: 0.2s;
                  }
               }


// - - - - форма для кнопки
            .formzaya-block-right-but {
               width: 100%;
               height: 50px;
               margin-top: 25px;
               float: left;
               background: none;
               & #knopka_zayavka {
                  display: inline-block;
                  width: 250px;
                  font-size: 100%;
                  margin-top: 0px;
                  margin-left: 90px;
                  color: rgba(255,255,255,.9);
                  text-shadow: #2e7ebd 0 1px 2px;
                  text-decoration: none;
                  text-align: center;
                  line-height: 1.1;
                  white-space: pre-line;
                  padding: .7em 0;
                  border: 1px solid;
                  border-color: #60a3d8 #2970a9 #2970a9 #60a3d8;
                  border-radius: 10px;
                  outline: none;
                  background: #60a3d8 linear-gradient(#89bbe2, #60a3d8 50%, #378bce);
                  box-shadow: inset rgba(255,255,255,.5) 1px 1px;  
                  cursor: pointer;
                  &:hover {
                     color: rgb(255,255,255);
                     background-image: linear-gradient(#9dc7e7, #74afdd 50%, #378bce);
                  }
                  &:active {
                     color: rgb(255,255,255);
                     border-color: #2970a9;
                     background-image: linear-gradient(#5796c8, #6aa2ce);
                     box-shadow: none;
                  }  
                  @media (max-device-width: 780px) {
                     margin-left: 70px;
                  }      
               }
               @media (max-device-width: 900px) {
                  margin-top: 12px;
                  margin-left: 150px;
                  width: 400px;
               } 
               @media (max-device-width: 780px) {
                  margin-left: 30px;
               } 
            }

      // - - - форма успешной отправки заявки
            .formzaya-saccess {
               position: absolute;
               width: 600px;
               height: 100px;
               left: 325px;
               top: 70px;
               background: #fff;
               border-radius: 20px;
               border: 1px dotted $coltxt;
               -webkit-box-shadow: 3px 5px 5px 3px rgba(0,0,0,0.3);
               -moz-box-shadow: 3px 3px 5px 3px rgba(0,0,0,0.3);
               box-shadow: 3px 3px 5px 3px rgba(0,0,0,0.3);
               animation-name: titleAnimationSucces;
               animation-timing-function: ease;
               animation-duration: 1s;
               cursor: pointer;
               & p {
                  width: 90%;
                  margin-left: 5%;
                  text-align: center;
                  margin-top: 10px;
                  letter-spacing: 1px;
               } 
               @media (max-device-width: 900px) {
                  left: 90px;
                  top: 150px;
               }
               @media (max-device-width: 780px) {
                  left: 20px;
                  width: 440px;
                  height: 120px;
               }    
            }

            @keyframes titleAnimationSucces {
               0% {
                  transform: translateY(100px);
                  opacity: 0;
               }
               100% {
                  transform: translateY(0px);
                  opacity: 1;
               }
            }