$colred: #F33131;
$colblue: #6ACCF9;
$colyell: #FED176;
$colgreen: #66E69A;
$coltxt: #393939;
$colspan: #243976;

.zakanimation {
   width: 100%;
   height: 680px;
   float: left;
   position: relative;
   background: none;
   @media (max-device-width: 1180px) {
      height: 720px;
   }
}
.zakanimation-center {
   width: 1180px;
   min-width: 1180px;
   height: 100%;
   margin: auto;
   background: none;
   position: relative;
   @media (max-device-width: 1180px) {
      width: 780px;
      min-width: 780px;
   }
   @media (max-device-width: 780px) {
      width: 480px;
      min-width: 480px;
   }
}

// - - - - блок с названием раздела
   .zakanimation-span {
      width: 400px;
      height: 40px;
      margin-left: 30px;
      background: none;
      position: relative;
      float: left;
   }
      .zakanimation-span-num {
         width: 40px;
         height: 40px;
         background: $colblue;
         float: left;
         border-radius: 20px;
         & p {
            font-size: 1.2em;
            font-weight: 600;
            line-height: 40px;
            width: 100%;
            color: #fff
         }
      }
      .zakanimation-span-txt {
         width: 350px;
         height: 40px;
         background: $colblue;
         float: left;
         border-radius: 20px;
         margin-left: 10px;
         & p {
            font-size: 1.1em;
            font-weight: 600;
            line-height: 40px;
            width: 100%;
            color: #fff;
         }
      }

// - - - - блок с роботом и описанием
      .zakanimation-blocktop {
         width: 1180px;
         height: 300px;
         background: none;
         float: left;
         margin-top: 20px;
         @media (max-device-width: 1180px) {
            width: 780px;
         }
         @media (max-device-width: 780px) {
            width: 480px;
            height: 330px;
         }
      }
      // - блок с описанием
         .blocktop-left {
            width: 500px;
            height: 300px;
            background: none;
            float: left;
            margin-left: 80px;
            @media (max-device-width: 1180px) {
               margin-left: 200px;
            }
            @media (max-device-width: 780px) {
               width: 350px;
               margin-left: 10px;
            }
         }
         .blocktop-left-li {
            width: 450px;
            height: 150px;
            background: none;
            float: left;
            margin-left: 70px;
            margin-top: 10px;
            @media (max-device-width: 780px) {
               width: 250px;
               margin-left: 190px;
               height: 230px;
               & li {
                  text-align: left;
               }
            }
         }
         .blocktop-left-txt {
            width: 400px;
            height: 100px;
            background: $colred;
            border-radius: 20px;
            float: left;
            margin-left: 50px;
            & p {
               font-size: 1.2em;
               width: 100%;
               margin-top: 25px;
               color: #fff;
               @media (max-device-width: 780px) {
                  width: 90%;
                  margin-left: 5%;
                  margin-top: 15px;
               }
            }
            @media (max-device-width: 780px) {
               width: 350px;
               height: 90px;
               margin-left: 55px;
            }
         }

         // - блок с роботом
         .blocktop-right {
            width: 400px;
            height: 320px;
            background: none;
            float: right;
            margin-right: 80px;
            @media (max-device-width: 1180px) {
               width: 300px;
               height: 240px;
               margin-top: 50px;
               margin-right: 20px;
            }
            @media (max-device-width: 780px) {
               display: none;
            }
         }
            .blocktop-right-img {
               width: 400px;
               height: 420px;
               background: none;
               float: left;
               margin-top: -50px;
               @media (max-device-width: 1180px) {
                  width: 300px;
                  height: 240px;
               }
            }
      
// - - - - блок с котом и описнаием
      .zakanimation-blockdown {
         width: 1180px;
         height: 320px;
         background: none;
         float: left;
         @media (max-device-width: 1180px) {
            width: 780px;
         }
         @media (max-device-width: 780px) {
            width: 480px;
         }
      }
   // - блок с котом
         .blockdown-left {
            width: 230px;
            height: 230px;
            background: none;
            float: left;
            margin-left: 50px;
            @media (max-device-width: 1180px) {
               margin-top: -300px;
               margin-left: 0px;
            }
            @media (max-device-width: 780px) {
               width: 160px;
               margin-top: -255px;
            }
         }
            .blockdown-left-img {
               width: 170px;
               height: 170px;
               background: none;
               float: left;
               margin-left: 80px;
               margin-top: 80px;
               @media (max-device-width: 1180px) {
                  margin-left: 20px;
                  margin-top: 40px;
               }  
                @media (max-device-width: 780px) {
                  width: 140px;
               }
            }
   // - блок с описанием 
         .blockdown-right {
            width: 800px;
            margin-right: 20px;
            height: 170px;
            background: $colyell;
            border-radius: 20px;
            float: right;
            margin-top: 80px;
            @media (max-device-width: 1180px) {
               width: 450px;
               float: left;
               margin-left: 10px;
               height: 260px;
               margin-top: 20px;
            }
            @media (max-device-width: 780px) {
               margin-left: 15px;
            }
         }
            .blockdown-right-txt {
               width: 90%;
               height: 40px;
               background: none;
               float: left;
               margin-left: 5%;
               margin-top: 15px;
               & p {
                  text-align: left;
                  font-size: 1.1em;
                  font-weight: 600;
                  margin-top: 15px;
               }
            }
            .blockdown-right-li {
               width: 90%;
               margin-left: 7%;
               height: 150px;
               float: left;
               background: none;
               & li {
                  text-align: left;
                  width: 100%;
               }
               @media (max-device-width: 1180px) {
                  margin-top: 40px;;
               }
            }