$colred: #F33131;
$colblue: #6ACCF9;
$colyell: #FED176;
$colgreen: #66E69A;
$coltxt: #393939;
$colspan: #243976;

.zaktipsayt {
   width: 100%;
   height: 2350px;
   float: left;
   position: relative;
   background: none;
   @media (max-device-width: 1180px) {
      height: 2830px;
   }
   @media (max-device-width: 780px) {
      height: 3850px;
   }
}
.zaktipsayt-center {
   width: 1180px;
   min-width: 1180px;
   height: 100%;
   margin: auto;
   background: none;
   position: relative;
   @media (max-device-width: 1180px) {
      width: 780px;
      min-width: 780px;
   }
   @media (max-device-width: 780px) {
      width: 480px;
      min-width: 480px;
   }
}

// - - - - блок с названием раздела
.zaktipsayt-span {
   width: 400px;
   height: 40px;
   margin-left: 30px;
   background: none;
   position: relative;
   float: left;
}
   .zaktipsayt-span-num {
      width: 40px;
      height: 40px;
      background: $colgreen;
      float: left;
      border-radius: 20px;
      & p {
         font-size: 1.2em;
         font-weight: 600;
         line-height: 40px;
         width: 100%;
         color: #fff
      }
   }
   .zaktipsayt-span-txt {
      width: 350px;
      height: 40px;
      background: $colgreen;
      float: left;
      border-radius: 20px;
      margin-left: 10px;
      & p {
         font-size: 1.1em;
         font-weight: 600;
         line-height: 40px;
         width: 100%;
         color: #fff;
      }
   }


// - - - - блок с лендингом
   .zaktipsayt-land {
      width: 100%;
      height: 530px;
      float: left;
      position: relative;
      background: none;
      @media (max-device-width: 1180px) {
         height: 600px;
      }
      @media (max-device-width: 780px) {
         height: 1030px;
      }
   }

   // - блок с описанием и ценами
      .land-left {
         width: 800px;
         height: 600px;
         background: none;
         float: left;
         @media (max-device-width: 1180px) {
            width: 780px;
         }
         @media (max-device-width: 780px) {
            width: 480px;
         }
      }
         .land-left-top {
            width: 750px;
            height: 250px;
            background: $colyell;
            float: left;
            margin-left: 80px;
            margin-top: 30px;
            @media (max-device-width: 1180px) {
               width: 780px;
               margin-left: 0px;
            }
            @media (max-device-width: 780px) {
               width: 480px;
               height: 300px;
            }
         }
         .land-left-top-text {
            width: 600px;
            height: 200px;
            background: none;
            float: left;
            @media (max-device-width: 1180px) {
               width: 700px;
            }
            @media (max-device-width: 780px) {
               width: 460px
            }
            & p {
               width: 90%;
               margin-left: 10%;
               text-align: left;
               margin-bottom: 20px;
               margin-top: 30px;
               font-size: 1.2em;
               font-weight: 600;
            }
            & li {
               width: 90%;
               margin-left: 10%;
               text-align: left;
               @media (max-device-width: 1180px) {
                  &:last-child {
                     width: 50%;
                  }
               }
               @media (max-device-width: 780px) {
                  &:last-child {
                     width: 90%;
                  }
               }

            }
         }
         .land-left-top-round {
            width: 100px;
            height: 100px;
            background: $colred;
            float: left;
            border-radius: 50px;
            margin-top: 200px;
            background: linear-gradient(-45deg, $colred 0, $colred 43%, #eda7a7 50%, $colred 57%, $colred 100%);
            background-size: 400% 400%;
            animation: gradient 8s ease infinite;
            @media (max-device-width: 1180px) {
               margin-top: -30px;
               margin-left: -50px;
            }
            &:last-child {
               margin-top: 260px;
               margin-left: -120px;
            }
         }

         p.red-round {
            color: #fff;
            width: 100%;
            line-height: 100px;
            font-size: 1.3em;
            
         }

         // - блок голуой с доп услугами
         .land-left-down {
            width: 600px;
            height: 150px;
            float: left;
            background: none;
            margin-top: -20px;
            @media (max-device-width: 1180px) {
               width: 300px;
               margin-left: 100px;
            }
            @media (max-device-width: 780px) {
               width: 480px;
               margin-left: 0px;
               margin-top: 550px;
               position: relative;
               z-index: 2;
            }
         }  
            .land-left-down-blue {
               width: 220px;
               height: 110px;
               float: left;
               background: $colblue;
               border-radius: 30px;
               @media (max-device-width: 780px) {
                  width: 200px;
                  height: 100px;
               }
               &.col-01 {
                  margin-left: 50px;
                  @media (max-device-width: 1180px) {
                     margin-left: 0px;
                  }
                  @media (max-device-width: 780px) {
                     margin-left: 10px;
                  }
               }
               &.col-02 {
                  margin-left: 30px;
                  @media (max-device-width: 1180px) {
                     margin-left: 0px;
                     margin-top: 20px;
                  }
                  @media (max-device-width: 780px) {
                     margin-left: 10px;
                     margin-top: -40px;
                  }
               }
               & p {
                  width: 80%;
                  margin-left: 10%;
                  font-size: 1.2em;
                  margin-top: 15px;
                  color: #fff;
                  @media (max-device-width: 780px) {
                     margin-top: 10px;
                  }
               }
            }
            .land-left-down-red {
               width: 70px;
               height: 45px;
               float: left;
               background: $colred;
               border-radius: 20px;
               margin-left: -50px;
               margin-top: 90px;
               background: linear-gradient(-45deg, $colred 0, $colred 43%, #eda7a7 50%, $colred 57%, $colred 100%);
               background-size: 400% 400%;
               animation: gradient 8s ease infinite;
               @media (max-device-width: 780px) {
                  margin-top: 80px;
               }
            }



            p.red-pos {
               width: 100%;
               line-height: 45px;
               color: #fff;
               font-size: 1.1em;
            }

      // - блок с картикной
      .land-right {
         width: 300px;
         height: 600px;
         background: none;
         float: left;
         @media (max-device-width: 1180px) {
            margin-top: -330px;
            float: right;
            margin-right: 30px;
            width: 250px;
            height: 500px;
            position: relative;
            z-index: 5;
         }
         @media (max-device-width: 780px) {
            margin-top: -200px;
            float: left;
            margin-left: 30px;
            width: 300px;
            height: 600px;
         }
      }
      .land-right-img {
         width: 300px;
         height: 600px;
         background: none;
         float: left;
         margin-top: -100px;
         animation-name: rot-01;
         animation-timing-function:linear;
         animation-duration: 8s;
         animation-iteration-count: infinite;
         @media (max-device-width: 1180px) {
            width: 250px;
            height: 500px;
         }
         @media (max-device-width: 780px) {
            width: 300px;
            height: 600px;
         }
      }






// - - - - блок с корпоративным сайтом
      .zaktipsayt-korp {
         width: 100%;
         height: 420px;
         background: none;
         position: relative;
         float: left;
         @media (max-device-width: 1180px) {
            height: 720px;
         }
         @media (max-device-width: 780px) {
            height: 800px;
         }
      }
         .korp-left {
            width: 500px;
            height: 500px;
            float: left;
            background: none;
            position: relative;
            z-index: 2;
            margin-top: -110px;
            @media (max-device-width: 1180px) {
               margin-top: 230px;
               margin-left: 10px;
            }
            @media (max-device-width: 780px) {
               width: 460px;
               margin-top: 0px;
               height: 360px;
            }
            
         }
            .korp-left-img {
               width: 500px;
               height: 500px;
               float: left;
               background: none;
               & img {
                  -webkit-box-shadow: 3px 5px 5px 0px rgba(0,0,0,0.3);
                  -moz-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.3);
                  box-shadow: 7px 7px 5px 0px rgba(0,0,0,0.3);
               }
               @media (max-device-width: 780px) {
                  width: 460px;
               }
            }
         
         .korp-right {
            width: 800px;
            height: 500px;
            float: right;
            background: none;
            margin-left: -200px;
            position: relative;
            z-index: 1;
            @media (max-device-width: 1180px) {
               width: 780px;
               margin-left: 0px;
               margin-top: -740px;
            }
            @media (max-device-width: 780px) {
               width: 480px;
               margin-top: 0px;
               position: relative;
               z-index: 15;
            }
         }
            .korp-right-top {
               width: 800px;
               height: 300px;
               float: left;
               background: $colyell;
               @media (max-device-width: 1180px) {
                  width: 780px;
               }
               @media (max-device-width: 780px) {
                  width: 480px;
                  position: relative;
                  z-index: 15;
                  height: 330px;
               }
            }
               .korp-right-top-round {
                  width: 100px;
                  height: 100px;
                  float: left;
                  background: $colred;
                  border-radius: 50px;
                  margin-top: 260px;
                  margin-left: 50px;
                  background: linear-gradient(-45deg, $colred 0, $colred 43%, #eda7a7 50%, $colred 57%, $colred 100%);
                  background-size: 400% 400%;
                  animation: gradient 8s ease infinite;
                  @media (max-device-width: 1180px) {
                     float: right;
                     margin-top: 100px;
                     margin-right: 30px;
                  }
                  @media (max-device-width: 780px) {
                     margin-top: -50px;
                     position: relative;
                     z-index: 15;
                  }
               }
               .korp-right-top-text {
                  width: 600px;
                  height: 300px;
                  float: left;
                  background: none;
                  margin-left: 0px;
                  & p {
                     width: 90%;
                     margin-left: 10%;
                     text-align: left;
                     margin-bottom: 20px;
                     margin-top: 30px;
                     font-size: 1.2em;
                     font-weight: 600;
                  }
                  & li {
                     width: 90%;
                     margin-left: 10%;
                     text-align: left;
                  }
                  @media (max-device-width: 780px) {
                     width: 460px;
                     margin-top: -40px;
                  }
               }
            // - блок голуой с доп услугами
            .korp-right-down {
               width: 600px;
               height: 150px;
               float: left;
               background: none;
               margin-top: -50px;
               margin-left: 200px;
               @media (max-device-width: 1180px) {
                  width: 300px;
                  float: right;
                  height: 300px;
               }
               @media (max-device-width: 780px) {
                  width: 480px;
                  margin-left: 0px;
                  height: 200px;
                  position: relative;
                  z-index: 16;
                  margin-top: -30px;
               }
            }  
               .korp-right-down-blue {
                  width: 220px;
                  height: 110px;
                  float: left;
                  background: $colblue;
                  border-radius: 30px;
                  @media (max-device-width: 780px) {
                     width: 200px;
                     height: 100px;
                  }
                  &.col-01 {
                     margin-left: 50px;
                     @media (max-device-width: 780px) {
                        margin-left: 10px;
                     }
                  }
                  &.col-02 {
                     margin-left: 30px;
                     @media (max-device-width: 1180px) {
                        margin-left: 50px;
                        margin-top: 20px;
                     }
                     @media (max-device-width: 780px) {
                        margin-left: 10px;
                        margin-top: 0px;
                     }
                  }
                  & p {
                     width: 80%;
                     margin-left: 10%;
                     font-size: 1.2em;
                     margin-top: 15px;
                     color: #fff;
                     @media (max-device-width: 780px) {
                        margin-top: 10px;
                     }
                  }
                  
               }
               .korp-right-down-red {
                  width: 70px;
                  height: 45px;
                  float: left;
                  background: $colred;
                  border-radius: 20px;
                  margin-left: -50px;
                  margin-top: 90px;
                  background: linear-gradient(-45deg, $colred 0, $colred 43%, #eda7a7 50%, $colred 57%, $colred 100%);
                  background-size: 400% 400%;
                  animation: gradient 8s ease infinite;
                  @media (max-device-width: 780px) {
                     margin-top: 80px;
                  }
                  
               }




      // - - - - блок с интрент порталом и сервисом
      .zaktipsayt-serv {
         width: 100%;
         height: 770px;
         background: none;
         position: relative;
         float: left;
         @media (max-device-width: 1180px) {
            height: 920px;
         }
         @media (max-device-width: 780px) {
            height: 1350px;
         }
      }
         .serv-top {
            width: 100%;
            height: 500px;
            background: none;
            float: left;

         }
            .serv-top-mobimg {
               width: 180px;
               height: 380px;
               background: none;
               float: left;
               margin-top: -100px;
               margin-left: 150px;
               position: relative;
               z-index: 3;
               animation-name: rot-02;
               animation-timing-function:linear;
               animation-duration: 8s;
               animation-iteration-count: infinite;
               @media (max-device-width: 1180px) {
                  margin-left: 30px;
               }
               @media (max-device-width: 780px) {
                  margin-top: 640px;
               }
            }
            .serv-top-deskimg {
               width: 580px;
               height: 410px;
               background: none;
               float: left;
               position: relative;
               z-index: 2;
               margin-left: -50px;
               & img {
                  -webkit-box-shadow: 3px 5px 5px 0px rgba(0,0,0,0.3);
                  -moz-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.3);
                  box-shadow: 7px 7px 5px 0px rgba(0,0,0,0.3);
               }
               @media (max-device-width: 780px) {
                  width: 460px;
                  margin-left: 10px;
                  margin-top: -1020px;
               }
            }
            .serv-top-foncolor {
               width: 370px;
               height: 510px;
               background: $colgreen;
               float: left;
               position: relative;
               z-index: 1;
               margin-left: -70px;
               @media (max-device-width: 1180px) {
                  margin-left: -350px;
                  margin-top: -160px;
               }
               @media (max-device-width: 780px) {
                  margin-top: -2350px;
                  float: right;
                  margin-right: 10px;
                  height: 540px;
                  width: 300px;
               }
            }

         .serv-down {
            width: 100%;
            height: 300px;
            background: none;
            float: left;
            margin-top: -130px;
            @media (max-device-width: 780px) {
               margin-top: -160px;
            }
         }
            .serv-down-left {
               width: 800px;
               height: 400px;
               background: none;
               float: left;
               @media (max-device-width: 1180px) {
                  width: 780px;
               }
               @media (max-device-width: 780px) {
                  width: 480px;
               }
               @media (max-device-width: 780px) {
                  margin-top: 10px;
               }
            }
               .serv-down-left-top {
                  width: 750px;
                  height: 300px;
                  background: $colyell;
                  float: left;
                  @media (max-device-width: 1180px) {
                     width: 780px;
                  }
                  @media (max-device-width: 780px) {
                     width: 480px;
                     height: 350px;
                  }
               }

               .serv-down-left-top-text {
                  width: 700px;
                  height: 200px;
                  background: none;
                  float: left;
                  & p {
                     width: 90%;
                     margin-left: 10%;
                     text-align: left;
                     margin-bottom: 20px;
                     margin-top: 80px;
                     font-size: 1.2em;
                     font-weight: 600;
                     @media (max-device-width: 1180px) {
                        margin-left: 3%;
                     }
                     @media (max-device-width: 780px) {
                        margin-left: 10%;
                        margin-top: 20px;
                        width: 70%;
                     }
                  }
                  & li {
                     width: 90%;
                     margin-left: 10%;
                     text-align: left;
                     @media (max-device-width: 1180px) {
                        margin-left: 3%;
                     }
                     @media (max-device-width: 780px) {
                        margin-left: 10%;
                     }
                  }
                  @media (max-device-width: 1180px) {
                     float: right;
                     height: 250px;
                  }
                  @media (max-device-width: 780px) {
                     float: left;
                     width: 460px;
                  }
               }
               .serv-down-left-top-round {
                  width: 100px;
                  height: 100px;
                  background: $colred;
                  float: left;
                  border-radius: 50px;
                  margin-top: 240px;
                  margin-left: -60px;
                  background: linear-gradient(-45deg, $colred 0, $colred 43%, #eda7a7 50%, $colred 57%, $colred 100%);
                  background-size: 400% 400%;
                  animation: gradient 8s ease infinite;
                  @media (max-device-width: 1180px) {
                     margin-top: -300px;
                     margin-left: 30px;
                  }
                  @media (max-device-width: 780px) {
                     float: right;
                     margin-right: 20px;
                     position: relative;
                     z-index: 20;
                  }
               }

               .serv-down-left-down {
                  width: 100%;
                  height: 100px;
                  background: none;
                  float: left;
                  margin-top: -50px;
                  @media (max-device-width: 1180px) {
                     width: 300px;
                     float: right;
                     margin-right: 10px;
                  }
               }
               .serv-down-left-down-blue {
                  width: 220px;
                  height: 110px;
                  float: left;
                  background: $colblue;
                  border-radius: 30px;
                  @media (max-device-width: 780px) {
                     width: 200px;
                     height: 100px;
                  }
                  &.col-01 {
                     margin-left: 50px;
                     @media (max-device-width: 780px) {
                        margin-left: 70px;
                     }
                  }
                  &.col-02 {
                     margin-left: 30px;
                     @media (max-device-width: 1180px) {
                        margin-left: 50px;
                        margin-top: 20px;
                     }
                     @media (max-device-width: 780px) {
                        margin-left: 70px;
                        margin-top: 10px;
                     }
                  }
                  & p {
                     width: 80%;
                     margin-left: 10%;
                     font-size: 1.2em;
                     margin-top: 15px;
                     color: #fff;
                     @media (max-device-width: 780px) {
                        margin-top: 10px;
                     }
                     
                  }
               }
               .serv-down-left-down-red {
                  width: 70px;
                  height: 45px;
                  float: left;
                  background: $colred;
                  border-radius: 20px;
                  margin-left: -50px;
                  margin-top: 90px;
                  background: linear-gradient(-45deg, $colred 0, $colred 43%, #eda7a7 50%, $colred 57%, $colred 100%);
                  background-size: 400% 400%;
                  animation: gradient 8s ease infinite;
                  @media (max-device-width: 780px) {
                     margin-top: 80px;    
                  }
               }

            .serv-down-right {
               width: 200px;
               height: 200px;
               background: none;
               float: left;
               position: relative;
               z-index: 5;
               margin-left: 120px;
               @media (max-device-width: 1180px) {
                  display: none;
               }
            }
               .serv-down-right-img {
                  width: 170px;
                  height: 170px;
                  background: none;
                  float: left;
               }



// - - - - - блок с интрент магазином
      .zaktipsayt-zakshop {
         width: 100%;
         height: 600px;
         background: none;
         float: left;
         @media (max-device-width: 1180px) {
            height: 550px;
         }
      }
         .zakshop-top {
            width: 100%;
            height: 500px;
            background: none;
            float: left;
            @media (max-device-width: 1180px) {
               height: 160px;
            }
         }
            .zakshop-top-left {
               width: 550px;
               height: 250px;
               background: none;
               float: left;
               @media (max-device-width: 1180px) {
                  float: right;
                  width: 300px;
                  height: 150px;
               }
            }
               .zakshop-top-left-coll {
                  width: 260px;
                  height: 300px;
                  background: $colgreen;
                  float: left;
                  margin-top: -100px;
                  margin-left: 100px;
                  @media (max-device-width: 1180px) {
                     display: none;
                  }
                  @media (max-device-width: 780px) {
                     display: block;
                     margin-top: -700px;
                     margin-left: -50px;
                     height: 600px;
                  }
               }
               .zakshop-top-left-price {
                  width: 250px;
                  height: 300px;
                  background: none;
                  float: left;
                  margin-left: -160px;
                  @media (max-device-width: 1180px) {
                     float: right;
                     margin-right: 10px;
                     height: 150px;
                  }
                  @media (max-device-width: 780px) {
                     margin-top: -420px;
                     width: 230px;
                  }
               }
                  .zakshop-top-left-price-blue {
                     width: 220px;
                     height: 110px;
                     float: left;
                     background: $colblue;
                     border-radius: 30px;
                     & p {
                        width: 80%;
                        margin-left: 10%;
                        font-size: 1.2em;
                        margin-top: 15px;
                        color: #fff;
                     }
                     @media (max-device-width: 780px) {
                        width: 200px;
                        height: 100px;
                     }
                  }
                  .zakshop-top-left-price-red {
                     width: 70px;
                     height: 45px;
                     float: left;
                     background: $colred;
                     border-radius: 20px;
                     margin-left: -50px;
                     margin-top: 90px;
                     background: linear-gradient(-45deg, $colred 0, $colred 43%, #eda7a7 50%, $colred 57%, $colred 100%);
                     background-size: 400% 400%;
                     animation: gradient 8s ease infinite;
                     @media (max-device-width: 780px) {
                        margin-top: 80px;
                     }
                  }


            .zakshop-top-right {
               width: 600px;
               height: 600px;
               background: none;
               float: right;
               margin-top: -100px;
               position: relative;
               z-index: 8;
               @media (max-device-width: 1180px) {
                  margin-top: -470px;
                  width: 500px;
                  height: 500px;
                  float: left;
               }
               @media (max-device-width: 780px) {
                  width: 480px;
               }
            }
               .zakshop-top-right-img {
                  width: 580px;
                  height: 590px;
                  background: none;
                  float: right;
                  animation-name: rot;
                  animation-timing-function:linear;
                  animation-duration: 15s;
                  animation-iteration-count: infinite;
                  @media (max-device-width: 1180px) {
                     width: 480px;
                     height: 500px;
                  }
               }

      .zakshop-down {
         width: 700px;
         height: 400px;
         background: none;
         float: left;
         margin-top: -270px;
         @media (max-device-width: 1180px) {
            margin-top: 0px;
            width: 780px;
         }
         @media (max-device-width: 780px) {
            width: 480px;
            margin-top: -10px;
         }
      }
         .zakshop-down-top {
            width: 700px;
            height: 300px;
            background: $colyell;
            float: none;
            @media (max-device-width: 1180px) {
               width: 780px;
               height: 280px;
            }
            @media (max-device-width: 780px) {
               width: 480px;
               height: 450px;
            }
         }
            .zakshop-down-top-text {
               width: 700px;
               height: 200px;
               background: none;
               float: left;
               @media (max-device-width: 1180px) {
                  width: 780px;
               }
               @media (max-device-width: 780px) {
                  width: 480px;
                  margin-top: 20px;
               }
               & p {
                  width: 80%;
                  margin-left: 7%;
                  text-align: left;
                  margin-bottom: 20px;
                  margin-top: 30px;
                  font-size: 1.2em;
                  font-weight: 600;
                  @media (max-device-width: 780px) {
                     width: 90%;
                  }
               }
               & li {
                  width: 80%;
                  margin-left: 7%;
                  text-align: left;
                  @media (max-device-width: 780px) {
                     width: 90%;
                  }
               }
            }
            .zakshop-down-top-round {
               width: 100px;
               height: 100px;
               background: $colred;
               float: left;
               border-radius: 50px;
               margin-top: 260px;
               margin-left: -120px;
               background: linear-gradient(-45deg, $colred 0, $colred 43%, #eda7a7 50%, $colred 57%, $colred 100%);
               background-size: 400% 400%;
               animation: gradient 8s ease infinite;
               @media (max-device-width: 1180px) {
                  margin-left: -170px;
                  margin-top: 240px;
               }
               @media (max-device-width: 780px) {
                  margin-top: -30px;
                  margin-left: -120px;
               }
            }

      .zakshop-down-down{
         width: 700px;
         height: 100px;
         background: none;
         float: none;
         margin-top: -50px;
         @media (max-device-width: 780px) {
            width: 480px;
            margin-top: -150px;
         }
      }
      .zakshop-down-down-blue {
         width: 220px;
         height: 110px;
         float: left;
         background: $colblue;
         border-radius: 30px;
         @media (max-device-width: 780px) {
            width: 200px;
            height: 100px;
         }
         &.col-01 {
            margin-left: 20px;
            @media (max-device-width: 780px) {
               margin-left: 10px;
            }
         }
         &.col-02 {
            margin-left: 30px;
            @media (max-device-width: 780px) {
               margin-left: 10px;
            }
         }
         & p {
            width: 80%;
            margin-left: 10%;
            font-size: 1.2em;
            margin-top: 15px;
            color: #fff;
            @media (max-device-width: 780px) {
               margin-top: 10px;
            }
         }
      }
      .zakshop-down-down-red {
         width: 70px;
         height: 45px;
         float: left;
         background: $colred;
         border-radius: 20px;
         margin-left: -50px;
         margin-top: 90px;
         background: linear-gradient(-45deg, $colred 0, $colred 43%, #eda7a7 50%, $colred 57%, $colred 100%);
         background-size: 400% 400%;
         animation: gradient 8s ease infinite;
         @media (max-device-width: 780px) {
            margin-top: 80px;
         }
      }

      .mobcol-01 {
         @media (max-device-width: 1180px) {
            margin-top: 110px;
         }
         @media (max-device-width: 780px) {
            margin-top: 40px;
         }
      }
      .mobcol-03 {
         @media (max-device-width: 1180px) {
            margin-top: 110px;
         }
         @media (max-device-width: 780px) {
            margin-top: 80px;
         }
      }
      .mobcol-04 {
         @media (max-device-width: 1180px) {
            margin-top: 110px;
         }
         @media (max-device-width: 780px) {
            margin-top: 90px;
         }
      }

      @keyframes rot {
         0% {
            transform: rotateY(0deg);
          }
          25% {
            transform: rotateY(30deg);
          }
          50% {
            transform: rotateY(0deg);
          }
          75% {
             transform: rotateY(20deg);
           }
           100% {
            transform: rotateY(0deg);
          }
       }
       @-webkit-keyframes rot {
         0% {
            transform: rotateY(0deg);
          }
          50% {
            transform: rotateY(30deg);
          }
          100% {
             transform: rotateY(0deg);
           }
       }

       @keyframes rot-01 {
         0% {
            transform: rotateY(0deg);
          }
          50% {
            transform: rotateY(30deg);
          }
          100% {
             transform: rotateY(0deg);
           }

      }
       @-webkit-keyframes rot-01 {
         0% {
            transform: rotateY(0deg);
          }
          50% {
            transform: rotateY(30deg);
          }
          100% {
             transform: rotateY(0deg);
           }
       }
       @keyframes rot-02 {
         0% {
            transform: rotate(0deg);
          }
          25% {
            transform: rotate(2deg);
          }
          50% {
            transform: rotate(-3deg);
          }
          100% {
             transform: rotate(0deg);
           }

      }
       @-webkit-keyframes rot-02 {
         0% {
            transform: rotateY(0deg);
          }
          50% {
            transform: rotateY(20deg);
          }
          100% {
             transform: rotateY(0deg);
           }
       }

       @keyframes gradient {
         0% {
            background-position: 0% 50%;
         }
         70% {
            background-position: 100% 50%;
            -webkit-box-shadow: 3px 5px 5px 0px rgba(0,0,0,0.3);
            -moz-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.3);
            box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.5);
         }
         100% {
            background-position: 100% 50%;
         }
      }