$colred: #F33131;
$colblue: #6ACCF9;
$colyell: #FED176;
$colgreen: #66E69A;
$coltxt: #393939;
$colspan: #243976;

.primertop {
   width: 100%;
   height: 970px;
   float: left;
   position: relative;
   background: none;
   @media (max-device-width: 1180px) {
      height: 800px;
   }

   @media (max-device-width: 780px) {
      height: 600px;
   }
}
.primertop-center {
   width: 1180px;
   min-width: 1180px;
   height: 100%;
   margin: auto;
   background: none;
   position: relative;
   @media (max-device-width: 1180px) {
      width: 780px;
      min-width: 780px;
   }
   @media (max-device-width: 780px) {
      width: 480px;
      min-width: 480px;
   }
}

   .primertop-big {
      width: 100%;
      height: 500px;
      background: none;
      float: left;
      border-radius: 20px;
      & img {
         height: 100%;
         border-radius: 20px;
      }
      cursor: pointer;
      @media (max-device-width: 1180px) {
         height: 400px;
      }
      @media (max-device-width: 780px) {
         height: 300px;
      }
   }



   .primertop-min {
      width: 100%;
      height: 400px;
      background: none;
      float: left;
      position: relative;
      margin-top: 20px;
      cursor: pointer;
      @media (max-device-width: 1180px) {
         height: 320px;
      }
      @media (max-device-width: 780px) {
         height: 250px;
      }
   }
   

   .primertop-col {
      width: 100%;
      height: 250px;
      background: $colyell;
      float: left;
      position: relative;
      margin-top: -370px;
      @media (max-device-width: 1180px) {
         margin-top: -340px;
         height: 230px;
      }
      @media (max-device-width: 780px) {
        margin-top: -250px;
        height: 200px;
      }
   }

   // - - - - вертушка
      .swiper {
         width: 100%;
         height: 400px;
         position: relative;
         float: left;
         @media (max-device-width: 1180px) {
            height: 320px;
         }
         @media (max-device-width: 780px) {
            height: 250px;
         }
      }
         .swiper-slide {
            text-align: center;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
         }      
            .swiper-slide img {
               display: block;
               width: 100%;
               height: 100%;
               object-fit: cover;
               // margin-left: 5%;
               border-radius: 20px;
            }
      .swiper-slide {
         width: 70%;
      }
      .swiper-slide:nth-child(1n) {
         width: 70%;
      }
      .swiper-slide:nth-child(2n) {
         width: 70%;
      }
      .swiper-slide:nth-child(3n) {
         width: 70%;
      }
      .swiper-button-prev {
         background: none !important;
      }
      .swiper-button-next {
         background: none !important;
      }


      .primertop-saitfor {
         width: 90%;
         height: 1050px;
         background: #6ACCF9;
         position: absolute;
         top:  0px;
         margin-left: 5%;
         z-index: 8888;
         animation-name: titleAnimation4;
         animation-timing-function:ease;
         animation-duration: 1s;
         -webkit-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
         -moz-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
         box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
         border-radius: 20px;
         @media (max-device-width: 1180px) {
            height: 700px;
         }
         @media (max-device-width: 780px) {
            height: 450px;
         }
         & .swiper {
            width: 100%;
            height: 100%;
            position: relative;
            float: left;
         }
            & .swiper-slide {
               text-align: center;
               background: #fff;
               display: flex;
               justify-content: center;
               align-items: center;
            }      
              & .swiper-slide img {
                  display: block;
                  width: 90%;
                  height: auto;
                  object-fit: cover;
                  border-radius: 20px;
               }
      }
               //анимация движения вылетающей статьи
               @keyframes titleAnimation4 {
                  0% {
                     transform: translateY(400px);
                     opacity: 0;
                  }
                  100% {
                        transform: translateY(0px);
                        opacity: 1;
                     
                  }
               }

               .primertop-saitfor-img {
                  width: 40px;
                  height: 40px;
                  position: absolute;
                  top: 10px;
                  right: 10px;
                  z-index: 9999;
                  background: none;
                  transition: 0.2s;
                  & img {
                     width: 100%;
                     height: 100%;
                     background: none;
                  }
                  opacity: 0.7;
                  &:hover {
                     opacity: 1;
                     cursor: pointer;
                     transition: 0.2s;
                  }
               }

      .im-1180 {
         display: block !important;
         @media (max-device-width: 1180px) {
            display: none !important;
         }
         @media (max-device-width: 780px) {
            display: none !important;
         }
      }

      .im-780 {
         display: none !important;
         @media (max-device-width: 1180px) {
            display: block !important;
         }
         @media (max-device-width: 780px) {
            display: none !important;
         }
      }

      .im-480 {
         display: none !important;
         @media (max-device-width: 1180px) {
            display: none !important;
         }
         @media (max-device-width: 780px) {
            display: block !important;
         }
      }