$colred: #F33131;
$colblue: #6ACCF9;
$colyell: #FED176;
$colgreen: #66E69A;
$coltxt: #393939;
$colspan: #243976;

.blogtop {
   width: 100%;
   height: 40px;
   background: none;
   position: relative;
   float: left;
   margin-bottom: 20px;
   @media (max-device-width: 1280px) {
      height: 80px;
   } 
   @media (max-device-width: 900px) {
      height: 40px;
   } 
}
   .blogtop-menu {
      width: 1260px;
      height: 100%;
      margin: auto;
      background: none;
      position: relative;
      @media (max-device-width: 1280px) {
         width: 1180px;
         min-width: 1180px;
      } 
      @media (max-device-width: 1180px) {
         width: 1080px;
         min-width: 1080px;
      } 
      @media (max-device-width: 1080px) {
         width: 900px;
         min-width: 900px;
      } 
      @media (max-device-width: 900px) {
         width: 780px;
         min-width: 780px;
      } 
      @media (max-device-width: 780px) {
         width: 480px;
         min-width: 480px;
      } 
   }
   

// стили для меню с хештегами
      .blogtop-menu-left {
         width: 1000px;
         height: 100%;
         margin: auto;
         background: none;
         position: relative;
         display: flex;
         float: left;
         column-gap: 10px;
         @media (max-device-width: 1280px) {
            width: 800px;
            min-width: 800px;
            flex-wrap: wrap;
            margin-left: 20px;
         } 
         @media (max-device-width: 1080px) {
            width: 600px;
            min-width: 600px;
         } 
         @media (max-device-width: 900px) {
            display: none;
         } 
         .blogtop-menu-hesh{
            width: auto;
            padding-left: 15px;
            padding-right: 15px;
            line-height: 40px;
            border-radius: 15px;
            cursor: pointer;
            transition: 0.2s all;
            &:hover {
               background: $colgreen;
               & p {
                  color: #fff;
                  transition: 0.1s all;
               }
               transition: 0.2s all;
            }
         }
         & .active {
            background: $colgreen;
            & p {
               color: #fff;
            }
         }

      }

// стили для меню с поиском
      .blogtop-menu-right {
         width: 250px;
         height: 100%;
         margin: auto;
         background: none;
         position: relative;
         float: right;
         // border: 1px solid black;
         @media (max-device-width: 900px) {
            width: 230px;
         }
      }
      .blogsearch-inp {
         border: 1px solid RGB(0, 0, 0, 0.3);
         padding: 4px 10px;
         width: 180px;
         border-radius: 10px;
         font-size: 14px;
         margin-top: 7px;
         padding-left: 25px;
         transition: 0.2 all;
         @media (max-device-width: 900px) {
            line-height: 22px;
            margin-top: 5px;
         }
         &:focus {
            border: 1px solid RGB(0, 0, 0, 0.5);
            transition: 0.2 all;
         }
      }
      .bclogsearch-icon {
         width: 16px;
         height: 16px;
         opacity: 0.5;
         position: absolute;
         top: 12px;
         left: 5px;
      }


//мобильно меню
   .blogtop-menu-mobile{
      width: 230px;
      height: 100%;
      margin: auto;
      background: none;
      position: relative;
      display: flex;
      float: left;
      column-gap: 10px;
      @media (max-device-width: 900px) {
         display: block;
         margin-left: 10px;
      } 
      @media (min-device-width: 900px) {
         display: none;
      } 

      .menu-mobile-cont {
         width: 220px;
         height: 30px;
         background: none;
         float: left;
         background: none;
         border: 1px solid RGB(0, 0, 0, 0.5);
         border-radius: 10px;
         margin-top: 5px;
         margin-left: 5px;

         .menu-mobile-cont-span {
            width: 150px;
            height: 100%;
            background: none;
            float: left;
            & p{
               width: 90%;
               line-height: 30px;
               margin-left: 10%;
               text-align: left;
            }
         }
         .menu-mobil-cont-img {
            width: 25px;
            height: 25px;
            background: none;
            float: right;
            margin-top: 2px;
            margin-right: 5px;
            & .image{
               opacity: 0.4;
            }
         }
      }
   }
   //выпадающий список
   .blogtop-menu-spisok {
      width: 250px;
      height: 260px;
      position: absolute;
      top: 40px;
      left: 15px;
      background: #fff;
      z-index: 2;
      border: 1px solid RGB(0, 0, 0, 0.5);
      border-radius: 10px;
      animation-name: menutransition;
      animation-timing-function:ease;
      animation-duration: 1s;

      .blogtop-menu-spisok-span {
         width: 90%;
         height: 35px;
         margin-left: 5%;
         border-bottom: 1px dashed $coltxt;
         & p{
            line-height: 35px;
            font-size: 1.1em;
         };
         &:hover {
            background: $colblue;
            border-radius: 5px;
         }
      }
   }

   @keyframes menutransition {
      0% {
         transform: translateX(-300px);
         opacity: 0;
      }
      100% {
            transform: translateY(00px);
            opacity: 1;
         
      }
   }
