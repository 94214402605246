$colred: #F33131;
$colblue: #6ACCF9;
$colyell: #FED176;
$colgreen: #66E69A;
$coltxt: #393939;
$colspan: #243976;

header {
   width: 100%;
   height: 100%;
   background: none;
   position: relative;
}

.top-header-menu {
   width: 1180px;
   min-width: 1180px;
   height: 100px;
   position: relative;
   background: none;
   margin: auto; 
   @media (max-device-width: 1180px) {
      width: 1080px;
      min-width: 1080px;
   } 
   @media (max-device-width: 1080px) {
      width: 780px;
      min-width: 780px;
   } 
   @media (max-device-width: 780px) {
      width: 480px;
      min-width: 480px;
   }
}


// - - - - - - логотип и назвнаие
   .top-header-menu-logo-img {
      width: 80px;
      height: 80px;
      float: left;
      margin-top: 16px;
      background: none;
   }
   .top-header-menu-logo {
      width: 450px;
      height: 100px;
      float: left;
      background: none;
      margin-top: 20px;
      @media (max-device-width: 1180px) {
         width: 350px;
      }
      p {
         width: 100%;
         font-family: 'Comfortaa', sans-serif;
         font-size: 3em;
         font-weight: 700;
         letter-spacing: 0px;
         color: #00868A;
         line-height: 70px;
         text-align: left;
         -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
         clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
         transform: translateY(-50px);
         opacity: 0;
         animation-name: titleAnimation;
         animation-timing-function:ease;
         animation-duration: 6s;
         animation-iteration-count: infinite;
         margin-left: -5px;
         background: none;
      }
      em {
         font-weight: 700;
         color: #004449;
         font-style: normal;
         background: none;
      }
      p {
         animation-delay: 0.6s;
         -webkit-animation-fill-mode: forwards;
         animation-fill-mode: forwards;
       }
   }
   //анимация движения логотипа
   @keyframes titleAnimation {
      0% {
         transform: translateY(-50px);
         opacity: 0;
         -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
         clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
      }
      20% {
         transform: translateY(0);
         opacity: 1;
         -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
         clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
      }
      80% {
            transform: translateY(0);
            opacity: 1;
         -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
         clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
      }
      100% {
            transform: translateY(50px);
            opacity: 0;
         -webkit-clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
         clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
         
      }
   }

// - - - - - блок с меню
   .top-header-menu-main {
      width: 650px;
      height: 100px;
      float: left;
      background: none;
      margin-top: 20px;
      @media (max-device-width: 1080px) {
         width: 350px;
      }
      @media (max-device-width: 780px) {
         margin-top: 45px;
         margin-left: -200px;
         width: 250px;
      } 
   }

// - - - - - верхнее меню
      .top-header-menu-main-top {
         height: 40px;
         background: none;
         border-bottom: 1px solid $colred;
      }

// - - - - - - нижнее меню
      .top-header-menu-main-low {
         height: 50px;
         background: none;
         @media (max-device-width: 780px) {
            height: 90px;
         } 
   // - - - - меню десктоп версии
         & .low-header-menu-txt {
            background: none;
            margin-top: 5px;
            // margin-left: 40px;
            width: auto;
            // padding: 0 18px 0 18px;
            cursor: pointer;
            float: left;
            transition: 0.2s;
            @media (max-device-width: 1080px) {
               display: none;
            }
            .menu-txt-butt {
               // width: 100%;
               background: none;
               height: 25px;
               transition: 0.2s;
               &:hover {
                  // border-bottom: 1px solid black;
                  p {
                     font-size: 1.15em;
                     // color: $colspan; 
                     transition: 0.2s;
                     background: none;
                  }
               }
            }
            .b01 {width: 100px}
            .b02 {width: 140px}
            .b03 {width: 210px}
            .b04 {width: 75px}
            .b05 {width: 110px}
            & p {
               font-size: 1em;
               width: 100%;
               margin-right: 5px;
               transition: 0.2s;
               background: none;
            }
         }
      }

// - - - - - значки для звонков
         .menu-main-top-soc, .menu-main-top-soc-mob {
            width: 110px;
            height: 100%;
            float: left;
            margin-left: 20px;
            background: none;
            & .menu-main-top-soc-img {
               width: 34px;
               height: 34px;
               float: left;
               margin-top: 2px;
               background: none;
              & img {
                  width: 26px;
                  height: auto;
                  margin-left: 5px;
                  margin-top: 5px;
                  transition: 0.2s;
                  background: none;
                  &:hover {
                     width: 30px;
                     margin-left: 3px;
                     margin-top: 3px;
                     transition: 0.2s;
                  }
               }
            }
            & .mobile-vers {
               @media (max-device-width: 780px) {
                  display: none;
               } 
            }
         }
         .menu-main-top-soc {
            display: block;
            @media (max-device-width: 1080px) {
               display: none;
            } 
         }
         .menu-main-top-soc-mob {
            width: 150px;
            float: right;
            margin-left: 20px;
            display: none;
            @media (max-device-width: 1080px) {
               display: block;
            }
            @media (max-device-width: 780px) {
               height: 50%;
               background: none;
               width: 110px;
               margin-left: 0px;
            } 
         }

// - - - - мобильное меню 780px (иконка)
                  .menu-main-mobilecss {
                     width: 150px;
                     height: 100%;
                     float: right;
                     margin-left: 20px;
                     display: none;
                     background: none;
                     @media (max-device-width: 780px) {
                        display: block;
                     } 
                     & .menu-main-top-soc-img {
                        width: 34px;
                        height: 34px;
                        float: left;
                        margin-top: 2px;
                        background: none;
                        @media (max-device-width: 780px) {
                           float: right;
                           margin-right: 15px;
                           margin-top: -10px;
                           width: 40px;
                           height: 40px;
                        } 
                       & img {
                           width: 26px;
                           height: auto;
                           margin-left: 5px;
                           margin-top: 5px;
                           transition: 0.2s;
                           background: none;
                           @media (max-device-width: 780px) {
                              width: 40px;
                              height: 40px;
                              margin-top: 0px;
                              margin-left: 0px;
                           }  
                        }
                     }
                  }

// - - - - - - - кнопка заявки
         .menu-main-top-but {
            background: none;
            width: 180px;
            height: 100%;
            float: left;
            @media (max-device-width: 1080px) {
               display: none;
            }
            & a.main-top-but{
               display: inline-block;
               width: 160px;
               font-size: 70%;
               margin-top: 2px;
               color: rgba(255,255,255,.9);
               text-shadow: #2e7ebd 0 1px 2px;
               text-decoration: none;
               text-align: center;
               line-height: 1.1;
               white-space: pre-line;
               padding: .7em 0;
               border: 1px solid;
               border-color: #60a3d8 #2970a9 #2970a9 #60a3d8;
               border-radius: 10px;
               outline: none;
               background: #60a3d8 linear-gradient(#89bbe2, #60a3d8 50%, #378bce);
               box-shadow: inset rgba(255,255,255,.5) 1px 1px;
             }
             & a.main-top-but:first-line{
               font-size: 120%;
               font-weight: 700;
             }
             & a.main-top-but:hover {
               color: rgb(255,255,255);
               background-image: linear-gradient(#9dc7e7, #74afdd 50%, #378bce);
             }
             & a.main-top-but:active {
               color: rgb(255,255,255);
               border-color: #2970a9;
               background-image: linear-gradient(#5796c8, #6aa2ce);
               box-shadow: none;
             }
         }
         .menu-but {
            width: 250px;
            height: 50px;
            margin-top: 30px;
            margin-left: 30px;
            @media (max-device-width: 1080px) {
               display: block;
            }
            & a.main-top-but {
               width: 250px;
               font-size: 100%;
               margin-top: 0px;
            }
         }


// - - - - - - телефонный номер
         .menu-main-top-tel, .menu-main-top-tel-mobile {
            background: none;
            width: 200px;
            height: 40%;
            & p {
               font-size: 1.2em;
               letter-spacing: 1px;
               line-height: 40px;
               margin-top: 0px;
               font-weight: 600;
            }
         }
         .menu-main-top-tel {
            display: block;
            float: left;
            @media (max-device-width: 780px) {
               display: none;
            } 
         }
         .menu-main-top-tel-mobile {
            display: none;
            float: right;
            @media (max-device-width: 780px) {
               display: block;
            } 
         }

// - - - - - переключатель языка
         .menu-main-top-lang, .menu-main-top-lang-mobile {
            width: 140px;
            height: 100%;
            float: left;
            z-index: 5;
            background: none;
            position: relative;
               & p {
                  height: 100%;
                  font-size: 1.1em;
                  line-height: 40px;
                  padding: 0px 5px;
                  font-weight: 600;
               }
               .switch {
                  margin-top: 5px;
                  position: relative;
                  width: 60px;
                  height: 30px;
                  float: left;
                  background: none;
               }
               .switch input {display:none;}
               .slider {
                  position: absolute;
                  cursor: pointer;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  background: #ccc;
                  -webkit-transition: .4s;
                  transition: .4s;
               }
               .slider:before {
                  position: absolute;
                  content: "";
                  height: 24px;
                  width: 24px;
                  left: 5px;
                  bottom: 3px;
                  background-color: white;
                  -webkit-transition: .4s;
                  transition: .4s;
               }
               input:checked + .slider {
                  background-color: #2196F3;
               }
               input:focus + .slider {
                  box-shadow: 0 0 1px #2196F3;
               }
               input:checked + .slider:before {
                  -webkit-transform: translateX(26px);
                  -ms-transform: translateX(26px);
                  transform: translateX(26px);
               }
               .slider.round {
                  border-radius: 30px;
               }
               .slider.round:before {
                  border-radius: 50%;
               }
            }
            .menu-main-top-lang {
               display: block;
               @media (max-device-width: 780px) {
                  display: none;
               } 
            }
            .menu-main-top-lang-mobile {
               display: none;
               @media (max-device-width: 780px) {
                  display: block;
               } 
            }


// - - - - - - мобильное меню форма
            .menu-mobile-fon {
               width: 320px;
               height: 400px;
               // border: 1px solid $coltxt;
               border-radius: 0 20px 20px 0;
               position: absolute;
               left: 0;
               top: 80px;
               background: #fff;
               z-index: 10;
               animation-name: titleAnimation3;
               animation-timing-function:ease;
               animation-duration: 1s;
               -webkit-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
               -moz-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
               box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
               .menu-mobile-fon-punkt {
                  width: 290px;
                  height: 50px;
                  position: relative;
                  float: left;
                  border-bottom: 1px dashed $colred;
                  margin-left: 10px;
                  background: none;
                  .menu-mobile-fon-punkt-txt {
                     width: 260px;
                     height: 100%;
                     float: left;
                     background: none;
                     & p {
                        font-size: 1.4em;
                        line-height: 50px;
                     }
                  }
                  .menu-mobile-fon-punkt-round {
                     width: 30px;
                     height: 30px;
                     margin-top: 10px;
                     border-radius: 15px;
                     float: left;
                     background: none;
                  }
                  .round-red {
                     background-color: $colred;
                  }
                  .round-blue {
                     background-color: $colblue;
                  }
                  .round-green {
                     background-color: $colgreen;
                  }
                  .round-yell {
                     background-color: $colyell;
                  }
                  .round-grey {
                     background-color: #A0A0A0;
                  }
               }
               .menu-main-close {
                  width: 50%;
                  margin-left: 25%;
                  background: none;
                  float: left;
                  height: 30px;
                  margin-top: 15px;
                  border-bottom: 1px dashed red;
                  & p {
                     font-size: 1.2em;
                     letter-spacing: 1px;
                     width: 100%;
                  }
               }
            }
               //анимация движения моильного меню
            @keyframes titleAnimation3 {
               0% {
                  transform: translateX(-300px);
                  opacity: 0;
               }
               100% {
                     transform: translateY(00px);
                     opacity: 1;
                  
               }
            }

      .forma-zayv {
         width: 910px;
         height: 380px;
         left: 20px;
         position: absolute;
         z-index: 999;
         background: #fff;
         border: 1px dashed black;
         border-radius: 30px;
         -webkit-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
         -moz-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
         box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
         animation-name: titleAnimation3;
         animation-timing-function:ease;
         animation-duration: 1s;
         @media (max-device-width: 950px) {
            width: 890px;
            left: 0px;
         } 
         @media (max-device-width: 900px) {
            width: 790px;
            left: 0px;
            height: 480px;
         } 
         @media (max-device-width: 790px) {
            width: 780px;
            left: 0px;
         } 
         @media (max-device-width: 780px) {
            width: 100%;
            left: 0px;
            height: 530px;
         } 
         .form-zayv-top {
            width: 100%;
            height: 80px;
            background: none;
            .form-zayv-top-left {
               width: 80%;
               height: 100%;
               background: none;
               float: left;
               @media (max-device-width: 540px) {
                  width: 70%;
               } 
               & span {
                  width: 90%;
                  margin-left: 50px;
                  font-size: 1.4em;
                  text-align: left;
                  margin-top: 20px;
                  letter-spacing: 2px;
                  @media (max-device-width: 540px) {
                     margin-left: 20px;
                  } 
               }
               & p {
                  width: 90%;
                  margin-left: 50px;
                  font-size: 1em;
                  text-align: left;
                  margin-top: -5px;
                  @media (max-device-width: 540px) {
                     margin-left: 20px;
                  } 
               }
            }
            .form-zayv-top-right {
               width: 15%;
               height: 100%;
               background: none;
               float: right;
               @media (max-device-width: 540px) {
                  width: 20%;
               } 
               & p {
                  width: 90%;
                  margin-top: 15px;
                  margin-left: 35px;
                  font-size: 1em;
                  text-align: left;
                  letter-spacing: 1px;
                  cursor: pointer;
                  transition: 0.2s;
                  @media (max-device-width: 950px) {
                     margin-left: 0px;
                  } 
                  &:hover {
                     transition: 0.2s;
                     color: $colred;
                  }
               }
               .form-zayv-top-right-line {
                  width: 80%;
                  margin-left: 10%;
                  background: none;
                  border-bottom: 1px dashed black;
                  float: left;
                  @media (max-device-width: 950px) {
                     width: 90px;
                     margin-left: -10px;
                  } 
               }

            }
         }
         .form-zayv-cneter {
            background: none;
            width: 800px;
            height: 300px;
            position: relative;
            margin: auto;
            margin-top: 20px;
            margin-left: -150px;
            @media (max-device-width: 1180px) {
               margin-left: -80px;
            } 
            @media (max-device-width: 1080px) {
               margin-left: 10px;
            } 
            @media (max-device-width: 950px) {
               margin-left: 0px;
            } 
            @media (max-device-width: 900px) {
               width: 790px;
            } 
            @media (max-device-width: 790px) {
               width: 780px;
               margin-left: 0px;
            } 
            @media (max-device-width: 780px) {
               width: 480px;
               margin-left: auto;
               margin-left: auto;
            } 
         }
      }