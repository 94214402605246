$colred: #F33131;
$colblue: #6ACCF9;
$colyell: #FED176;
$colgreen: #66E69A;
$coltxt: #393939;
$colspan: #243976;

.zakuslugy {
   width: 100%;
   height: 590px;
   float: left;
   position: relative;
   background: none;
   @media (max-device-width: 780px) {
      height: 680px;
   }
}
.zakuslugy-center {
   width: 780px;
   min-width: 780px;
   height: 100%;
   margin: auto;
   background: none;
   position: relative;
   @media (max-device-width: 780px) {
      width: 480px;
      min-width: 480px;
   }
}

// - - - стиль для названия раздела
      .zakuslugy-span {
         width: 100%;
         height: 70px;
         position: relative;
         float: left;
         background: none;
         & p {
            width: 100%;
            font-size: 1.2em;
            color: $colspan;
            text-align: center;
            line-height: 70px;
            letter-spacing: 1px;
            font-weight: 600;
         }
      }

// - - - стиль для блока с контентом
      .zakuslugy-blockcont {
         width: 760px;
         height: 120px;
         margin-left: 10px;
         background: none;
         position: relative;
         float: left;
         @media (max-device-width: 800px) {
            width: 720px;
            margin-left: 30px;
         }
         @media (max-device-width: 780px) {
            width: 460px;
            margin-left: 10px;
            height: 150px;
         }
      }
         .blockcont-round {
            width: 100px;
            height: 100px;
            border-radius: 70px;
            background: none;
            float: left;
         }
         .blockcont-txt {
            width: 500px;
            height: 100px;
            border-radius: 20px;
            background: $colgreen;
            float: left;
            margin-left: 30px;
            margin-right: 30px;
            & p {
               font-size: 1.2em;
               width: 80%;
               margin-left: 10%;
               margin-top: 10px;
               @media (max-device-width: 780px) {
                  margin-left: 15%;
                  text-align: left;
                  font-size: 1.1em;
                  width: 70%;
               }
            }
            @media (max-device-width: 800px) {
               margin-left: 10px;
               margin-right: 10px;
            }
            @media (max-device-width: 780px) {
               width: 400px;
               min-width: 400px;
               margin-left: -50px;
               height: 120px;
            }
         }

         // - стиль для картинки
            .r-img {
               background: none;
               position: relative;
               animation-name: AnimRottate;
               animation-timing-function: ease;
               animation-duration: 15s;
               animation-iteration-count: infinite;
               @media (max-device-width: 780px) {
                  margin-top: 10px;
               }
            }

         // - стиль для цветного кружка
            .r-col {
               background: $colred;
               position: relative;
               & span {
                  color: #fff;
                  width: 100%;
                  line-height: 90px;
                  font-size: 1.3em;
                  @media (max-device-width: 780px) {
                     line-height: 70px;
                  }
               }
               & p {
                  color: #fff;
                  width: 100%;
                  line-height: 15px;
                  font-size: 1.1em;
                  margin-top: -35px;
                  @media (max-device-width: 780px) {
                     margin-top: -25px;
                  }
               }
               @media (max-device-width: 780px) {
                  width: 80px;
                  height: 80px;
                  margin-left: -80px;
                  margin-top: 60px;
               }
            }

   @keyframes AnimRottate {
      0% {
         transform: rotate(0deg);
         }
      25% {
         transform: rotate(360deg);
         }
      50% {
            transform: rotate(0deg);
            }
      100% {
         transform: rotate(0deg);
         }
      }

      .block-mobile {
         @media (min-device-width: 780px) {
            display: none;
         }
         @media (max-device-width: 780px) {
            display: block;
         }
      }
      .block-desktop {
         @media (min-device-width: 780px) {
            display: block;
         }
         @media (max-device-width: 780px) {
            display: none;
         }
      }