$colred: #F33131;
$colblue: #6ACCF9;
$colyell: #FED176;
$colgreen: #66E69A;
$coltxt: #393939;
$colspan: #243976;


.headerdown {
   width: 100%;
   height: 100%;
   background: none;
   position: relative;
   float: left;
}
.low-header-menu {
   width: 1180px;
   min-width: 1180px;
   height: 600px;
   position: relative;
   background: none;
   margin: auto;
   @media (max-device-width: 1180px) {
      width: 1080px;
      min-width: 1080px;
      height: 570px;
   } 
   @media (max-device-width: 1080px) {
      width: 780px;
      min-width: 780px;
      height: 530px;
   } 
   @media (max-device-width: 780px) {
      width: 480px;
      min-width: 480px;
      height: 400px;
   }
}
   .low-header-cent {
      width: 1132px;
      height: 600px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      background: none;
      margin: auto;
      @media (max-device-width: 1180px) {
         width: 1080px;
         min-width: 1080px;
         height: 570px;
      } 
      @media (max-device-width: 1080px) {
         width: 780px;
         min-width: 780px;
         height: 530px;
      } 
      @media (max-device-width: 780px) {
         width: 480px;
         min-width: 480px;
         height: 400px;
      }
      
// - - - - - стиль для боковой линии
      .low-header-line {
         width: 50px;
         height: 500px;
         border-left: 2px dotted $colspan;
         border-bottom: 2px dotted $colspan;
         float: left;
         position: relative;
         margin-top: -45px;
         background: none;
         @media (max-device-width: 1180px) {
            display: none;
         } 
      }
   }

// - - - - - стиль для надписи под логотипом
      .low-header-underlogo {
         width: 310px;
         height: 200px;
         background: none;
         position: relative;
         float: left;
         margin-top: -45px;
         margin-left: -30px;
         @media (max-device-width: 1180px) {
            margin-left: 20px;
         } 
         @media (max-device-width: 780px) {
            margin-left: 10px;
            margin-top: -70px;
            width: 200px;
            height: 120px;
         } 
         p {
            width: 100%;
            font-family: 'Comfortaa', sans-serif;
            font-size: 2.5em;
            font-weight: 400;
            letter-spacing: 0px;
            color: #004449;
            line-height: 43px;
            text-align: left;
            -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
            clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
            transform: translateY(-50px);
            opacity: 0;
            animation-name: titleAnimation;
            animation-timing-function:ease;
            animation-duration: 6s;
            animation-iteration-count: infinite;
            @media (max-device-width: 780px) {
               font-size: 1.8em;
               line-height: 35px;
            } 
         }
         //анимация движения логотипа
         @keyframes titleAnimation {
            0% {
               transform: translateY(-50px);
               opacity: 0;
               -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
               clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
            }
            20% {
               transform: translateY(0);
               opacity: 1;
               -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
               clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
            }
            80% {
                  transform: translateY(0);
                  opacity: 1;
               -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
               clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
            }
            100% {
                  transform: translateY(50px);
                  opacity: 0;
               -webkit-clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
               clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
               
            }
         }
      }

// - - - - - - - стиль для блока с кошкой и цветных форм
      .low-header-cat {
         width: 800px;
         height: 450px;
         position: relative;
         float: left;
         background: none;
         @media (max-device-width: 1180px) {
            margin-left: -50px;
         } 
         @media (max-device-width: 1080px) {
            width: 780px;
            margin-left: 0px;
            top: 0;
            position: absolute;
         } 
         @media (max-device-width: 780px) {
            width: 480px;
            height: 260px;
            margin-left: 0px;
            top: 0;
            position: relative;
         } 
      }

// - - - - - - стили для цветного чекбокса (общий)
         .low-header-cat-onecolor {
            width: 380px;
            height: 50px;
            position: relative;
            float: left;
            background: none;
            @media (max-device-width: 1080px) {
               width: 310px;
               height: 45px;
               margin-top: -20px;
            } 
            @media (max-device-width: 780px) {
               width: 450px;
               margin-left: 5px;
               margin-top: 10px;
            } 
            //стили для линий
            .cat-onecolor-lines {
               width: 200px;
               height: 100%;
               position: relative;
               float: left;
               background: none;
               @media (max-device-width: 1080px) {
                  width: 150px;
                  margin-right: 10px;
               } 
               @media (max-device-width: 780px) {
                  display: none;
               } 
               & .cat-onecolor-line {
                  width: 115px;
                  height: 5px;
                  background: none;
                  @media (max-device-width: 1080px) {
                     width: 80px;
                  } 
               }
               & .red-01 {
                  border-top: 1px solid $colred;
                  margin-top: 10px;
                  margin-left: 40px;
                  @media (max-device-width: 1080px) {
                     margin-left: 20px;
                  } 
               }
               & .red-02 {
                  border-top: 2px solid $colred;
                  margin-top: 5px;
                  margin-left: 70px;
               }
               & .red-03 {
                  border-top: 2px solid $colred;
                  margin-top: 10px;
                  margin-left: 20px;
                  @media (max-device-width: 1080px) {
                     margin-left: 60px;
                  } 
               }
               & .yell-01 {
                  border-top: 2px solid $colyell;
                  margin-top: 10px;
                  margin-left: 40px;
               }
               & .yell-02 {
                  border-top: 3px solid $colyell;
                  margin-top: 8px;
                  margin-left: 70px;
               }
               & .yell-03 {
                  border-top: 2px solid $colyell;
                  margin-top: 6px;
                  margin-left: 30px;
               }
               & .blue-01 {
                  border-top: 1px solid $colblue;
                  margin-top: 15px;
                  margin-left: 60px;
                  background: none;
               }
               & .blue-02 {
                  border-top: 3px solid $colblue;
                  margin-top: 3px;
                  margin-left: 50px;
                  background: none;
               }
               & .blue-03 {
                  border-top: 2px solid $colblue;
                  margin-top: 7px;
                  margin-left: 70px;
                  background: none;
               }
               & .green-01 {
                  border-top: 2px solid $colgreen;
                  margin-top: 10px;
                  margin-left: 70px;
               }
               & .green-02 {
                  border-top: 1px solid $colgreen;
                  margin-top: 3px;
                  margin-left: 50px;
               }
               & .green-03 {
                  border-top: 2px solid $colgreen;
                  margin-top: 10px;
                  margin-left: 60px;
               }
            }
            //стиль для надписи
            .cat-onecolor-blocks-block-red {
               width: 180px;
               height: 100%;
               position: relative;
               float: left;
               border-radius: 25px;
               background: $colred;
               @media (max-device-width: 1080px) {
                  width: 150px;
               } 
               p {
                  width: 100%;
                  font-size: 1.6em;
                  line-height: 50px;
                  color: white;
                  transition: 0.3s ease;
                  @media (max-device-width: 1080px) {
                     font-size: 1.4em;
                     line-height: 45px;
                  }
                  &:hover {
                     letter-spacing: 2.5px;
                     transition: 0.3s ease;
                  } 
               }
            }
            //анимация движения цветных чекбоксов
            @keyframes titleAnimation2 {
               0% {
                  transform: translateX(-1000px);
                  opacity: 0;
               }
               100% {
                  transform: translatex(0px);
                  opacity: 1;
               }
            }
         }

// - - - - - - стиль для чекбокса (красный)      
         .onered {
            margin-left: 150px;
            animation-name: titleAnimation2;
            animation-timing-function:ease;
            animation-duration: 6s;
            @media (max-device-width: 1080px) {
               margin-left: 250px;
            } 
            @media (max-device-width: 780px) {
               margin-left: 5px;
            } 
         }

// - - - - - - стиль для чекбокса (желтый / синий) 
         .low-header-cat-twocolor {
            width: 800px;
            height: 70px;
            background: none;
            float: left;
            margin-top: 20px;
            @media (max-device-width: 1080px) {
               width: 680px;
               margin-left: 100px;
               margin-top: 20px;
            } 
            @media (max-device-width: 780px) {
               width: 450px;
               margin-left: 0px;
               height: 110px;
               margin-top: 10px;
            } 
         }
         // - - желтый
         .oneyellow {
            margin-left: -80px;
            margin-top: 50px;
            animation-name: titleAnimation2;
            animation-timing-function:ease;
            animation-duration: 5s;
            @media (max-device-width: 1080px) {
               margin-top: 70px;
               margin-left: -100px;               
            } 
            @media (max-device-width: 780px) {
               margin-left: 5px;
               margin-top: 0px;
            } 
         }
         .cat-onecolor-blocks-block-yellow {
            width: 180px;
            height: 100%;
            position: relative;
            float: left;
            border-radius: 25px;
            background: $colyell;
            @media (max-device-width: 1080px) {
               width: 150px;
            } 
            p {
               width: 100%;
               font-size: 1.6em;
               line-height: 50px;
               color: white;
               transition: 0.3s ease;
               @media (max-device-width: 1080px) {
                  font-size: 1.4em;
                  line-height: 45px;
               } 
               &:hover {
                  letter-spacing: 4px;
                  transition: 0.3s ease;
               } 
            }
         }
         // - - синий
         .oneblue {
            margin-left: 110px;
            margin-top: -10px;
            animation-name: titleAnimation2;
            animation-timing-function:ease;
            animation-duration: 7s;
            @media (max-device-width: 1080px) {
               margin-left: 160px;
            } 
            @media (max-device-width: 780px) {
               margin-left: 5px;
               margin-top: 10px;
            } 
         }
         .cat-onecolor-blocks-block-blue {
            width: 180px;
            height: 100%;
            position: relative;
            float: left;
            border-radius: 25px;
            background: $colblue;
            @media (max-device-width: 1080px) {
               width: 150px;
            } 
            p {
               width: 100%;
               font-size: 1.6em;
               line-height: 50px;
               color: white;
               transition: 0.3s ease;
               @media (max-device-width: 1080px) {
                  font-size: 1.4em;
                  line-height: 45px;
               }
               &:hover {
                  letter-spacing: 4px;
                  transition: 0.3s ease;
               }  
            }
         }

// - - - - - - стиль для чекбокса (зеленый) 
         .low-header-cat-threecolor {
            width: 800px;
            height: 60px;
            background: none;
            float: left;
            margin-top: 20px;
            @media (max-device-width: 1080px) {
               width: 780px;
            } 
            @media (max-device-width: 780px) {
               width: 450px;
               margin-top: 0px;
            } 
             .cat-onecolor-blocks-block-green {
               width: 180px;
               height: 100%;
               position: relative;
               float: left;
               border-radius: 25px;
               background: $colgreen;
               @media (max-device-width: 1080px) {
                  width: 150px;
               } 
               p {
                  width: 100%;
                  font-size: 1.6em;
                  line-height: 50px;
                  color: white;
                  transition: 0.3s ease;
                  background: none;
                  @media (max-device-width: 1080px) {
                     font-size: 1.4em;
                     line-height: 45px;
                  }
                  &:hover {
                     letter-spacing: 3px;
                     transition: 0.3s ease;
                  } 
               }
            }
            .onegreen {
               margin-left: -180px;
               margin-top: 80px;
               animation-name: titleAnimation2;
               animation-timing-function:ease;
               animation-duration: 4s;
               @media (max-device-width: 1080px) {
                  margin-left: -40px;
                  margin-top: 60px;
               } 
               @media (max-device-width: 780px) {
                  margin-left: 5px;
                  margin-top: 0px;
               } 
            }
         }

// - - - - стиль для картинки кота
         .cat-onecolor-img {
            width: 600px;
            height: 400px;
            float: left;
            margin-top: -70px;
            margin-left: -20px;
            background: none;
            @media (max-device-width: 1080px) {
               width: 550px;
               height: 370px;
               margin-top: -90px;
               margin-left: -70px;
            } 
            @media (max-device-width: 780px) {
               width: 350px;
               height: 270px;
               margin-top: -220px;
               margin-right: -30px;
               float: right;
            } 
         }

// - - - - стиль для картинки программиста
      .low-header-img {
         width: 300px;
         height: 300px;
         float: left;
         position: relative;
         margin-top: -120px;
         margin-left: 0px;
         background: none;
         @media (max-device-width: 1180px) {
            display: block;
         } 
         @media (max-device-width: 1080px) {
            margin-top: 420px;
            margin-left: -300px;
         } 
         @media (max-device-width: 780px) {
            margin-top: 130px;
            margin-left: 100px;

         } 
      }
      //смена картинки
      .spanDiv {
         @media (max-device-width: 1080px) {
            display: none;
         } 
      }

// - - - - стиль для названия раздела и описания
      .low-header-textcomon {
         width: 600px;
         height: 80px;
         margin-left: 170px;
         margin-top: 70px;
         float: left;
         position: relative;
         background: none;
         
         p {
            width: 100%;
            font-size: 1em;
         }
         @media (max-device-width: 1180px) {
            margin-top: -100px;
            margin-left: 460px;
         } 
         @media (max-device-width: 1080px) {
            width: 400px;
            height: 120px;
            margin-left: 350px;
            margin-top: 330px;
            p {
               text-align: left;
               font-size: 1.1em;
            }
         } 
         @media (max-device-width: 780px) {
            width: 450px;
            height: 120px;
            margin-left: 25px;
            margin-top: -430px;
            p {
               text-align: left;
               font-size: 1.2em;
            }
         } 
      }
      .spanTxt {
         width: 600px;
         height: 40px;
         margin-top: 90px;
         border-bottom: 1px dashed $coltxt;
         & p {
            font-size: 1.5em;
            font-weight: 600;
            margin-top: 0px;
         }
         @media (max-device-width: 1180px) {
            margin-top: -70px;
            margin-bottom: 50px;
         }
         @media (max-device-width: 1080px) {
            width: 400px;
            height: 40px;
            margin-left: 200px;
            margin-top: 300px;
            margin-bottom: 30px;
            p {
               text-align: center;
               font-size: 1.5em;
            }
         } 
         @media (max-device-width: 780px) {
            width: 400px;
            // height: 120px;
            margin-left: 40px;
            margin-top: 10px;
            margin-bottom: 20px;

            & p {
               margin-top: 10px;
               text-align: center;
            }
         } 
      }


