.zakbanner {
   width: 100%;
   height: 550px;
   background: url(../img/fon_02.jpg);
   background-origin: content-box;
   background-size: cover;
   background-position: center;
   position: relative;
   float: left;
   margin-bottom: 30px;
}
   .zakbanner-center {
      width: 1280px;
      min-width: 1280px;
      height: 100%;
      margin: auto;
      background: none;
      @media (max-device-width: 1280px) {
         width: 1180px;
         min-width: 1180px;
      } 
      @media (max-device-width: 1180px) {
         width: 1080px;
         min-width: 1080px;
      } 
      @media (max-device-width: 1080px) {
         width: 900px;
         min-width: 900px;
      } 
      @media (max-device-width: 900px) {
         width: 780px;
         min-width: 780px;
      } 
      @media (max-device-width: 780px) {
         width: 480px;
         min-width: 480px;
      } 
   }

// -  - - - блок с контентом
         .zakbanner-block {
            width: 600px;
            height: 80px;
            background: none;
            position: relative;
            float: right;
            margin-left: 200px;
            @media (max-device-width: 900px) {
               float: left;
               margin-left: 40px;
            } 
            @media (max-device-width: 780px) {
               float: left;
               margin-left: 20px;
               width: 450px;
            } 
         }
         .zakbanner-block:first-child {
            margin-top: 20px;
         }

      // блок в виде ромба
         .block-revers {
            width: 40px;
            height: 40px;
            transform: rotate(45deg);
            background: none;
            border: 2px dotted #fff;
            float: left;
            margin-top: 29px;
            animation-name: AnimColor;
            animation-timing-function: ease;
            animation-duration: 10s;
            animation-iteration-count: infinite;
         }
      // правый блок
         .block-right {
            width: 500px;
            height: 100px;
            float: left;
            background: none;
            margin-left: 11px;
            @media (max-device-width: 780px) {
               width: 380px
            } 
         }
            .block-right-txt {
               width: 100%;
               height: 50px;
               background: none;
               float: left;
               & p {
                  text-align: left;
                  font-size: 1.3em;
                  font-weight: 600;
                  line-height: 30px;
                  margin-left: 20px;
                  margin-top: 20px;
                  color: #fff;
                  @media (min-device-width: 780px) {
                     animation-name: AnimMovie;
                  } 
                  @media (max-device-width: 780px) {
                     animation-name: AnimMovieMob;
                  } 
                  animation-timing-function: ease;
                  animation-duration: 10s;
                  animation-iteration-count: infinite;
               }
               p.sec-01 {
                  animation-delay: 0s;
               }
               p.sec-02 {
                  animation-delay: 0.3s;
               }
               p.sec-03 {
                  animation-delay: 0.6s;
               }

            }
            .block-right-line {
               width: 100%;
               background: none;
               height: 50px;
               border-top: 2px dotted #fff;
               float: left;
               animation-name: AnimColor;
               animation-timing-function: ease;
               animation-duration: 10s;
               animation-delay: 1s;
               animation-iteration-count: infinite;
            }
         

                     //анимация движения вылетающей статьи
         @keyframes AnimColor {
            0% {
               border-color: #fff;
               // transform: rotate(-10deg);
            }
            50% {
               border-color: red;
            }
            100% {
               border-color: #fff;
            }
         }
         @keyframes AnimMovie {
            0% {
               margin-left: 300px;
               opacity: 0;
            }
            20% {
               color: red;
            }
            40% {
               color: #fff;
            }
            50% {
               margin-left: 20px;
               opacity: 1;
            }
            80% {
               margin-left: 20px;
               opacity: 1;
            }
            100% {
               margin-left: -10px;
               opacity: 0;
            }
         }
         @keyframes AnimMovieMob {
            0% {
               margin-left: 200px;
               opacity: 0;
            }
            20% {
               color: red;
            }
            40% {
               color: #fff;
            }
            50% {
               margin-left: 20px;
               opacity: 1;
            }
            80% {
               margin-left: 20px;
               opacity: 1;
            }
            100% {
               margin-left: -10px;
               opacity: 0;
            }
         }