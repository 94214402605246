$colred: #F33131;
$colblue: #6ACCF9;
$colyell: #FED176;
$colgreen: #66E69A;
$coltxt: #393939;
$colspan: #243976;

.aboutme {
   width: 100%;
   height: 580px;
   background: none;
   position: relative;
   float: left;
   @media (max-device-width: 1280px) {
      height: 630px;
   } 
   @media (max-device-width: 900px) {
      height: 1030px;
   } 
   @media (max-device-width: 780px) {
      height: 980px;
   } 
}
   .aboutme-center {
      width: 1280px;
      min-width: 1280px;
      height: 100%;
      margin: auto;
      background: none;
      @media (max-device-width: 1280px) {
         width: 1080px;
         min-width: 1080px;
      } 
      @media (max-device-width: 1080px) {
         width: 900px;
         min-width: 900px;
      } 
      @media (max-device-width: 900px) {
         width: 780px;
         min-width: 780px;
      } 
      @media (max-device-width: 780px) {
         width: 480px;
         min-width: 480px;
      } 
   }

// - - - - блок с названием раздела
      .aboutme-top {
         width: 100%;
         height: 40px;
         background: none;
         float: left;
         position: relative;
      }
      .aboutme-top-span {
         width: 45%;
         height: 40px;
         background: $colgreen;
         border-radius: 20px;
         float: left;
         margin-left: 15%;
         & p {
            font-size: 1.3em;
            line-height: 40px;
            text-align: right;
            width: 95%;
            font-weight: 600;
            letter-spacing: 1px;
         }
         @media (max-device-width: 900px) {
            width: 60%;
            margin-left: 2%;
         } 
         @media (max-device-width: 780px) {
            width: 70%;
            margin-left: 2%;
         } 
      }

//- - - - блок с описанием и кратинокй
      .aboutme-down {
         width: 100%;
         height: 500px;
         background: none;
         position: relative;
         float: left;
         margin-top: 20px;
      }

      //блок с картинкой
         .aboutme-down-img {
            width: 500px;
            height: 500px;
            background: none;
            float: left;
            margin-left: 50px;
            & img {
               background: none;
            }
            @media (max-device-width: 1280px) {
               margin-left: 30px;
               margin-top: 30px;
            } 
            @media (max-device-width: 1080px) {
               width: 400px;
               height: 400px;
            } 
            @media (max-device-width: 900px) {
               margin-top: 180px;
               width: 500px;
               height: 500px;
               margin-left: 90px;
            } 
            @media (max-device-width: 780px) {
               width: 450px;
               height: 420px;
               margin-left: 20px;
               margin-top: 220px;
            } 
         }
         

      //блок с описанием
         .aboutme-down-opis {
            width: 600px;
            height: 500px;
            background: none;
            float: left;
            margin-left: 50px;
            @media (max-device-width: 1280px) {
               margin-left: 30px;
               width: 500px;
            } 
            @media (max-device-width: 1080px) {
               width: 450px;
               margin-left: 10px;
            } 
            @media (max-device-width: 900px) {
               margin-left: 40px;
               width: 700px;
               height: 270px;
            }
            @media (max-device-width: 780px) {
               width: 450px;
               margin-left: 20px;
               height: 270px;
            } 
         }
         // блок с описанием верхним на беллом фоне
            .aboutme-down-opis-top {
               width: 500px;
               height: 230px;
               background: none;
               float: left;
               margin-left: 50px;
               & p {
                  width: 100%;
                  text-align: left;
                  margin-top: 10px;
               }
               @media (max-device-width: 1280px) {
                  width: 400px;
                  height: 270px;
               } 
               @media (max-device-width: 1080px) {
                  margin-left: 25px;
               } 
               @media (max-device-width: 900px) {
                  margin-top: -685px;
                  width: 600px;
                  margin-left: 50px;
               } 
               @media (max-device-width: 780px) {
                  width: 400px;
                  margin-left: 25px;
                  margin-top: -660px;
               } 
            }
            
            //блок с рпсианием на желтом фоне
            .aboutme-down-opis-down {
               width: 600px;
               height: 230px;
               background: $colyell;
               float: left;
               margin-top: 0px;
               border-radius: 20px;
               @media (max-device-width: 1280px) {
                  width: 500px;
                  height: 235px;
               } 
               @media (max-device-width: 1080px) {
                  width: 450px;
               } 
               @media (max-device-width: 900px) {
                  width: 600px;
                  margin-left: 50px;
               } 
               @media (max-device-width: 780px) {
                  width: 440px;
                  margin-left: 0px;
               } 
            }
             .aboutme-down-opis-down-txt {
               width: 500px;
               height: 160px;
               background: none;
               float: left;
               margin-left: 50px;
               & p {
                  width: 100%;
                  text-align: left;
                  margin-top: 10px;
               }
               @media (max-device-width: 1280px) {
                  width: 400px;
                  height: 165px;
               } 
               @media (max-device-width: 1080px) {
                  margin-left: 25px;
               } 
               @media (max-device-width: 900px) {
                  width: 500px;
                  margin-left: 50px;
               } 
               @media (max-device-width: 780px) {
                  width: 400px;
                  margin-left: 20px;
               } 
             }

             // - - - кнопка
             .aboutme-down-opis-down-but {
               width: 350px;
               height: 50px;
               margin-left: 130px;
               background: $colblue;
               border-radius: 20px;
               float: left;
               -webkit-box-shadow: 3px 5px 5px 0px rgba(0,0,0,0.3);
               -moz-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.3);
               box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.3);
               & p {
                  width: 100%;
                  text-align: center;
                  font-size: 1.3em;
                  font-weight: 600;
                  color: #fff;
                  line-height: 50px;
                  transition: 0.3s;
                  &:hover{
                     letter-spacing: 2px;
                     transition: 0.3s;
                     cursor: pointer;
                  }
               }
               @media (max-device-width: 1280px) {
                  margin-left: 80px;
               } 
               @media (max-device-width: 1080px) {
                  margin-left: 50px;
               } 
               @media (max-device-width: 900px) {
                  margin-left: 130px;
               } 
               @media (max-device-width: 780px) {
                  margin-left: 45px;
               } 
             }