$colred: #F33131;
$colblue: #6ACCF9;
$colyell: #FED176;
$colgreen: #66E69A;
$coltxt: #393939;
$colspan: #243976;

.bodymain {
   width: 100%;
   height: 2150px;
   float: left;
   position: relative;
   background: none;
   @media (max-device-width: 1280px) {
      height: 2670px;
      margin-top: 30px;
   }
   @media (max-device-width: 780px) {
      height: 2900px;
      margin-top: 40px;
   }
}
.bodymain-center {
   width: 1280px;
   min-width: 1280px;
   height: 100%;
   margin: auto;
   background: none;
   position: relative;
   @media (max-device-width: 1280px) {
      width: 780px;
      min-width: 780px;
   }
   @media (max-device-width: 780px) {
      width: 480px;
      min-width: 480px;
   }
}

// - - - блок с лендинг-промо страницей
   .bodymain-lend {
      width: 1280px;
      height: 400px;
      float: left;
      position: relative;
      background: none;
      @media (max-device-width: 1280px) {
         width: 780px;
         min-width: 780px;
      }
      @media (max-device-width: 780px) {
         width: 480px;
         min-width: 480px;
         height: 850px;
      }
   }
   // - - - левый блок с картинкой
      .bodymain-land-left {
         width: 400px;
         height: 400px;
         background: none;
         float: left;
         margin-left: 100px;
         z-index: 10;
         position: relative;
         @media (max-device-width: 1280px) {
            margin-left: 10px;
            width: 360px;
         }
         @media (max-device-width: 780px) {
            margin-top: 80px;;
         }
      }
         .bodymain-land-left-collor {
            width: 350px;
            height: 370px;
            background: $colyell;
            float: left;
            z-index: 2;
            margin-top: 70px;
            @media (max-device-width: 780px) {
               margin-left: 20px;
            }
         }
         .bodymain-land-left-img {
            width: 320px;
            height: 400px;
            background: none;
            float: left;
            z-index: 3;
            margin-top: -500px;
            animation-name: rot-01;
            animation-timing-function:linear;
            animation-duration: 8s;
            animation-iteration-count: infinite;
            @media (max-device-width: 780px) {
               margin-left: 20px;
            }
         }
         .bodymain-land-left-line {
            width: 410px;
            height: 200px;
            background: none;
            border-left: 2px dotted $colspan;
            border-bottom: 2px dotted $colspan;
            float: left;
            position: absolute;
            z-index: 12;
            left: 200px;
            margin-top: 470px;
            @media (max-device-width: 1280px) {
               width: 200px;
               left: 50px;
               margin-top: 520px;
               height: 250px;
            }
            @media (max-device-width: 780px) {
               display: none;
            }
         }
         .line-round {
            width: 5px;
            height: 5px;
            border-radius: 3px;
            background: $colspan;
            &.circle-01 {
               float: left;
               margin-left: -3px;
               margin-top: -3px;
            }
            &.circle-02 {
               float: right;
               margin-right: -3px;
               margin-top: 198.5px;
               @media (max-device-width: 1280px) {
                  margin-top: 248.5px;
               }
            }
         }
   // - - - правый блок с описанием
      .bodymain-land-right {
         width: 650px;
         height: 250px;
         background: none;
         float: left;
         margin-left: 20px;
         margin-top: 50px;
         @media (max-device-width: 1280px) {
            width: 390px;
            margin-left: 10px;
         }
         @media (max-device-width: 780px) {
            position: relative;
            z-index: 2;
         }
      }
         .bodymain-land-right-span {
            width: 350px;
            height: 40px;
            background: $colgreen;
            border-radius: 20px;
            margin-left: 10px;
            margin-top: 30px;
            float: left;
            & P {
               text-align: center;
               color: #fff;
               font-size: 1.3em;
               letter-spacing: 2px;
               font-weight: 600;
               line-height: 40px;
               width: 100%;
               transition: 0.2s ease;
               &:hover {
                  letter-spacing: 3px;
                  transition: 0.3s ease;
               }
            }
            @media (max-device-width: 1280px) {
               margin-top: 10px;
            }
            @media (max-device-width: 780px) {
               margin-top: -530px;
               margin-left: 100px;
            }
         }
         .bodymain-land-right-txt {
            width: 500px;
            height: 150px;
            background: none;
            float: left;
            margin-top: 20px;
            margin-left: 100px;
            li {
               text-align: left;
               margin-left: 30px;
            }
            @media (max-device-width: 1280px) {
               width: 390px;
               margin-left: 5px;
            }
            @media (max-device-width: 780px) {
               width: 450px;
               margin-top: 130px;
               z-index: 2;
               height: 180px;
            }
         }

// - - - - юлок сайт-визитки
      .bodymain-visit {
         width: 1280px;
         height: 620px;
         float: left;
         position: relative;
         background: none;
         z-index: 0;
         margin-top: -90px;
         @media (max-device-width: 1280px) {
            width: 780px;
            height: 890px;
         }
         @media (max-device-width: 780px) {
            width: 480px;
            height: 730px;
         }
      }
   // - верхний блог с картинкой
         .bodymain-visit-top {
            width: 1280px;
            height: 400px;
            float: left;
            background: none;
            @media (max-device-width: 1280px) {
               width: 780px;
               height: 620px;
            }
            @media (max-device-width: 780px) {
               width: 480px;
               height: 560px;
            }
         }
            .bodymain-visit-top-collor {
               width: 350px;
               height: 480px;
               background: $colgreen;
               float: left;
               margin-left: 400px;
               z-index: 1;
               position: relative;
               @media (max-device-width: 1280px) {
                  margin-left: 280px;
               }
               @media (max-device-width: 780px) {
                  margin-top: -350px;
                  margin-left: 100px;
                  height: 800px;
               }
            }
            .bodymain-visit-top-img {
               width: 550px;
               height: 400px;
               background: none;
               float: left;
               margin-left: -60px;
               margin-top: 50px;
               position: relative;
               z-index: 2;
               & img {
                  -webkit-box-shadow: 3px 5px 5px 0px rgba(0,0,0,0.3);
                  -moz-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.3);
                  box-shadow: 7px 7px 5px 0px rgba(0,0,0,0.3);
               }
               @media (max-device-width: 1280px) {
                  margin-left: -440px;
                  margin-top: 230px;
               }
               @media (max-device-width: 780px) {
                  width: 450px;
                  margin-left: 10px;
                  margin-top: -290px;
               }
            }

   // - нижний блок с описанием
         .bodymain-visit-down {
            width: 1280px;
            height: 100px;
            float: left;
            background: none;
            @media (max-device-width: 1280px) {
               width: 780px;
               height: 250px;
            }
            @media (max-device-width: 780px) {
               width: 480px;
               height: 160px;
            }
         }
         .bodymain-visit-down-span {
            width: 350px;
            height: 40px;
            background: $colred;
            border-radius: 20px;
            margin-left: 150px;
            margin-top: 30px;
            float: left;
            z-index: 3;
            position: relative;
            & P {
               text-align: center;
               color: #fff;
               font-size: 1.3em;
               letter-spacing: 2px;
               font-weight: 600;
               line-height: 40px;
               width: 100%;
               transition: 0.2s ease;
               &:hover {
                  letter-spacing: 3px;
                  transition: 0.3s ease;
               }
            }
            @media (max-device-width: 1280px) {
               margin-left: 30px;
            }
            @media (max-device-width: 780px) {
               margin-top: -460px;
            }
         }
         .bodymain-visit-down-txt {
            width: 90%;
            height: 100px;
            float: left;
            background: none;
            margin-top: 20px;
            margin-left: 10%;
            li {
               text-align: left;
               margin-left: 30px;
            }
            @media (max-device-width: 1280px) {
               margin-left: 4%;
               width: 80%;
            }
            @media (max-device-width: 780px) {
               margin-top: -50px;
               width: 90%;
            }
         }

// - - - - блок с корпоративным сайтом
      .bodymain-korp{
         width: 1280px;
         height: 630px;
         float: left;
         position: relative;
         background: none;
         margin-top: 0px;
         @media (max-device-width: 1280px) {
            width: 780px;
         }
         @media (max-device-width: 780px) {
            width: 480px;
            height: 650px;
         }
      }
      // - верхний блог с картинкой
         .bodymain-korp-top {
            width: 1280px;
            height: 400px;
            float: left;
            background: none;
            position: relative;
            @media (max-device-width: 1280px) {
               width: 780px;
               // height: 800px;
            }
            @media (max-device-width: 780px) {
               width: 480px;
               height: 300px;
            }
         }
         .bodymain-korp-top-line {
            width: 320px;
            height: 250px;
            background: none;
            border-right: 2px dotted $colspan;
            border-bottom: 2px dotted $colspan;
            float: left;
            position: absolute;
            z-index: 9999;
            left: 900px;
            margin-top: -200px;
            @media (max-device-width: 1280px) {
               position: absolute;
               left: 580px;
               width: 140px;
               margin-top: -300px;
               height: 360px;
            }
            @media (max-device-width: 780px) {
               display: none;
            }
         }
         .line-round {
            width: 5px;
            height: 5px;
            border-radius: 3px;
            background: $colspan;
            &.circle-04 {
               float: left;
               margin-left: -3px;
               margin-top: 248px;
               @media (max-device-width: 1280px) {
                  margin-top: 358px;
               }
            }
            &.circle-05 {
               float: right;
               margin-right: -3.5px;
               margin-top: -3px;
            }
         }
            .bodymain-korp-top-imgmax {
               width: 570px;
               height: 400px;
               background: none;
               margin-left: 350px;
               float: left;
               position: relative;
               z-index: 2;
               & img {
                  -webkit-box-shadow: 3px 5px 5px 0px rgba(0,0,0,0.3);
                  -moz-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.3);
                  box-shadow: 7px 7px 5px 0px rgba(0,0,0,0.3);
               }
               @media (max-device-width: 1280px) {
                  margin-left: 30px;
               }
               @media (max-device-width: 780px) {
                  width: 400px;
                  margin-left: 10px;
                  z-index: 2;
                  margin-top: 60px;
               }
            }
            .bodymain-korp-top-imgmin {
               width: 200px;
               height: 400px;
               background: none;
               margin-left: -70px;
               margin-top: 80px;
               float: left;
               position: relative;
               z-index: 3;
               animation-name: rot-02;
               animation-timing-function:linear;
               animation-duration: 8s;
               animation-iteration-count: infinite;
               @media (max-device-width: 780px) {
                  width: 150px;
                  margin-top: -330px;
                  margin-left: 300px;
                  height: 300px;
               }
            }
            .bodymain-korp-top-collor {
               width: 800px;
               height: 400px;
               background: $colblue;
               float: left;
               position: relative;
               z-index: 1;
               margin-left: -600px;
               margin-top: -30px;
               @media (max-device-width: 1280px) {
                  width: 600px;
                  margin-left: -550px;
               }
               @media (max-device-width: 780px) {
                  width: 450px;
                  margin-left: 30px;
                  z-index: 1;
                  margin-top: -440px;
               }
            }
   // - нижний блок с описанием 
         .bodymain-korp-down {
            width: 1000px;
            height: 150px;
            float: left;
            background: none;    
            margin-left: 150px; 
            @media (max-device-width: 1280px) {
               width: 780px;
               height: 280px;
               margin-left: 0px;
            }
            @media (max-device-width: 780px) {
               width: 480px;
               height: 330px;
            }
         }
            .bodymain-korp-down-span {
               width: 350px;
               height: 40px;
               background: $colyell;
               border-radius: 20px;
               margin-left: 100px;
               margin-top: 50px;
               float: left;
               z-index: 3;
               position: relative;
               & P {
                  text-align: center;
                  color: #fff;
                  font-size: 1.3em;
                  letter-spacing: 2px;
                  font-weight: 600;
                  line-height: 40px;
                  width: 100%;
                  transition: 0.2s ease;
                  &:hover {
                     letter-spacing: 3px;
                     transition: 0.3s ease;
                  }
               }
               @media (max-device-width: 1280px) {
                  margin-top: 40px;
               }
               @media (max-device-width: 780px) {
                  margin-top: -300px;
               }
            }
            .bodymain-korp-down-txt {
               width: 660px;
               height: 100px;
               float: left;
               background: none;
               margin-top: 20px;
               margin-left: 200px;
               position: relative;
               z-index: 2;
               li {
                  text-align: left;
                  margin-left: 30px;
               }
               @media (max-device-width: 1280px) {
                  width: 680px;
                  margin-left: 80px;
               }
               @media (max-device-width: 780px) {
                  width: 450px;
                  margin-left: 20px;
                  margin-top: 140px;
               }
            }
            .bodymain-korp-down-collor {
               width: 420px;
               height: 480px;
               background: $colyell;
               float: left;
               position: relative;
               z-index: 0;
               margin-left: -290px;
               margin-top: -200px;
               @media (max-device-width: 1280px) {
                  margin-top: 110px;
                  margin-left: -420px;
                  height: 560px;
               }
               @media (max-device-width: 780px) {
                  width: 300px;
                  margin-top: 110px;
                  margin-left: 10px;
               }
               
            }

// - - - - блок с интернет магазином
      .bodymain-shop{
         width: 1280px;
         height: 600px;
         float: left;
         position: relative;
         background: none;
         margin-top: 0px;
         @media (max-device-width: 1280px) {
            width: 780px;
            height: 800px;
            margin-top: 30px;
         }
         @media (max-device-width: 780px) {
            width: 480px;
            margin-top: 20px;
            height: 690px;
         }
      }
         .bodymain-shop-line {
            width: 230px;
            height: 500px;
            background: none;
            border-left: 2px dotted $colspan;
            border-top: 2px dotted $colspan;
            float: left;
            position: absolute;
            z-index: 9999;
            left: 130px;
            margin-top: -515px;
            @media (max-device-width: 1280px) {
               border-top: none;
               height: 260px;
               margin-top: -280px;
               left: 70px;
            }
            @media (max-device-width: 780px) {
               display: none;
            }
         }
         .line-round {
            width: 5px;
            height: 5px;
            border-radius: 3px;
            background: $colspan;
            &.circle-06 {
               float: left;
               margin-left: -3.5px;
               margin-top: 500px;
               @media (max-device-width: 1280px) {
                  margin-top: 260px;
               }
            }
            &.circle-07 {
               float: right;
               margin-right: -3.5px;
               margin-top: -3px;
               @media (max-device-width: 1280px) {
                  margin-top: -1px;
                  margin-left: -5px;
                  float: left;
               }
            }

         }
      // - верхний блог с картинкой
         .bodymain-shop-left {
            width: 600px;
            height: 600px;
            float: left;
            background: none;
            @media (max-device-width: 1280px) {
               margin-left: 10px;
               height: 460px;
            }
            @media (max-device-width: 780px) {
               width: 460px;
            }
         }
            .bodymain-shop-left-img {
               width: 530px;
               height: 530px;
               float: left;
               background: none;
               margin-left: 50px;
               margin-top: -50px;
               animation-name: rot;
               animation-timing-function:linear;
               animation-duration: 15s;
               animation-iteration-count: infinite;
               @media (max-device-width: 1280px) {
                  margin-left: 30px;
               }
               @media (max-device-width: 780px) {
                  width: 450px;
                  margin-left: 10px;
                  margin-top: -10px;
               }
            }
            
         .bodymain-shop-right {
            width: 600px;
            height: 600px;
            float: left;
            background: none;
            @media (max-device-width: 1280px) {
               width: 780px;
               height: 280px;
            }
            @media (max-device-width: 780px) {
               width: 450px;
               height: 220px;
            }
         }
            .bodymain-shop-right-span {
               width: 350px;
               height: 40px;
               background: $colblue;
               border-radius: 20px;
               margin-left: 100px;
               margin-top: 120px;
               float: left;
               z-index: 3;
               position: relative;
               & P {
                  text-align: center;
                  color: #fff;
                  font-size: 1.3em;
                  letter-spacing: 2px;
                  font-weight: 600;
                  line-height: 40px;
                  width: 100%;
                  transition: 0.2s ease;
                  &:hover {
                     letter-spacing: 3px;
                     transition: 0.3s ease;
                  }
               }
               @media (max-device-width: 1280px) {
                  margin-top: 10px;
                  margin-left: 300px;
               }
               @media (max-device-width: 780px) {
                  margin-left: 110px;
                  margin-top: -500px;
               }
            }
            .bodymain-shop-right-txt {
               width: 600px;
               height: 200px;
               float: left;
               background: none;
               margin-top: 20px;
               margin-left: 50px;
               position: relative;
               z-index: 2;
               li {
                  text-align: left;
                  margin-left: 50px;
               }
               @media (max-device-width: 1280px) {
                  width: 500px;
                  margin-left: 10px;
               }
               @media (max-device-width: 780px) {
                  width: 450px;
                  margin-top: 0px;
                  li:last-child {
                     width: 250px;
                  }
               }
            }
            .bodymain-shop-right-img {
               width: 160px;
               height: 160px;
               float: right;
               margin-right: 50px;
               background: none;
               @media (max-device-width: 1280px) {
                  margin-top: 60px;
               }
               @media (max-device-width: 780px) {
                  width: 120px;
                  height: 120px;
                  margin-top: -100px;
                  margin-right: 10px;
               }
            }

            .bodymain-shop-right-line {
               width: 1050px;
               height: 100px;
               background: none;
               border-left: 2px dotted $colspan;
               border-bottom: 2px dotted $colspan;
               float: left;
               position: absolute;
               z-index: 12;
               left: 100px;
               margin-top: 450px;
               @media (max-device-width: 1280px) {
                  display: none;
               }
            }
            .line-round {
               width: 5px;
               height: 5px;
               border-radius: 3px;
               background: $colspan;
               &.circle-03 {
                  float: left;
                  margin-left: -3px;
                  margin-top: -3px;
               }
            }


            // @keyframes titleAnimationtxtSpan {
            //    0% {
            //       transform: translateY(-50px);
            //       opacity: 0;
            //       -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
            //       clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 100%);
            //    }
            //    20% {
            //       transform: translateY(0);
            //       opacity: 1;
            //       -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0%);
            //       clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0%);
            //    }
            //    80% {
            //          transform: translateY(0);
            //          opacity: 1;
            //       -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0%);
            //       clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0%);
            //    }
            //    100% {
            //          transform: translateY(50px);
            //          opacity: 0;
            //          -webkit-clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
            //          clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
                  
            //    }
            // }

            @keyframes rot {
               0% {
                  transform: rotateY(0deg);
                }
                25% {
                  transform: rotateY(30deg);
                }
                50% {
                  transform: rotateY(0deg);
                }
                75% {
                   transform: rotateY(20deg);
                 }
                 100% {
                  transform: rotateY(0deg);
                }
             }
             @-webkit-keyframes rot {
               0% {
                  transform: rotateY(0deg);
                }
                50% {
                  transform: rotateY(30deg);
                }
                100% {
                   transform: rotateY(0deg);
                 }
             }

             @keyframes rot-01 {
               0% {
                  transform: rotateY(0deg);
                }
                50% {
                  transform: rotateY(30deg);
                }
                100% {
                   transform: rotateY(0deg);
                 }

            }
             @-webkit-keyframes rot-01 {
               0% {
                  transform: rotateY(0deg);
                }
                50% {
                  transform: rotateY(30deg);
                }
                100% {
                   transform: rotateY(0deg);
                 }
             }
             @keyframes rot-02 {
               0% {
                  transform: rotate(0deg);
                }
                25% {
                  transform: rotate(2deg);
                }
                50% {
                  transform: rotate(-3deg);
                }
                100% {
                   transform: rotate(0deg);
                 }

            }
             @-webkit-keyframes rot-02 {
               0% {
                  transform: rotateY(0deg);
                }
                50% {
                  transform: rotateY(20deg);
                }
                100% {
                   transform: rotateY(0deg);
                 }
             }