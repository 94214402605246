$colred: #F33131;
$colblue: #6ACCF9;
$colyell: #FED176;
$colgreen: #66E69A;
$coltxt: #393939;
$colspan: #243976;

.ourwork {
   width: 100%;
   height: 480px;
   background: none;
   position: relative;
   float: left;
   @media (max-device-width: 1280px) {
      height: 520px;
   } 
   @media (max-device-width: 780px) {
      height: 570px;
   } 
}
.ourwork-center {
      width: 1180px;
      min-width: 1180px;
      height: 100%;
      margin: auto;
      background: none;
      @media (max-device-width: 1180px) {
         width: 780px;
         min-width: 780px;
      } 
      @media (max-device-width: 780px) {
         width: 480px;
         min-width: 480px;
      } 
   }

// - - - - блок с названием раздела
      .ourwork-top {
         width: 100%;
         height: 50px;
         background: none;
         float: left;
         position: relative;
         margin-bottom: 20px;
         & p{
            font-size: 1.4em;
            font-weight: 600;
            letter-spacing: 1px;
            width: 100%;
            line-height: 50px;
            background: none;
            transition: 0.4s;
            color: $colspan;
            &:hover {
               letter-spacing: 1.5px;
               transition: 0.4s;
            }
         }
      }

// - - - - блок с менбшками (описание + картинки)
      .ourwork-down {
         width: 100%;
         height: 500px;
         background: none;
         float: left;
         position: relative;
         @media (max-device-width: 1180px) {
            height: 400px;
         } 
         @media (max-device-width: 780px) {
            height: 450px;
         } 
      }

   // - - общий блок с опсианием меню
      .ourwork-down-studio {
         width: 450px;
         height: 120px;
         float: left;
         background: none;
         margin-left: 100px;
         @media (max-device-width: 1180px) {
            margin-left: 20px;
         } 
         .ourwork-down-studio-img {
            width: 110px;
            height: 110px;
            margin-top: 5px;
            margin-left: 10px;
            background: none;
            float: left;   
            &:hover {
               transform: rotate(360deg);
               transition: 2s;
               cursor: pointer;
            }
         }
         .ourwork-down-studio-txt {
            width: 250px;
            margin-left: 20px;
            height: 80px;
            margin-top: 15px;
            float: left;
            background: none;
            animation-name: titleAnimationtxt;
            animation-timing-function:ease;
            animation-duration: 6s;
            animation-iteration-count: infinite;
            & p {
               font-size: 1.1em;
               text-align: left;
               background: none;
               color: $colspan;
            }
         }  
      }
      .prime-02, .prime-04 {
         margin-left: 120px;
         .ourwork-down-studio-txt {
            margin-left: 50px;
            height: 75px;
            margin-top: 30px;
            & p{
               text-align: right;
            }
         }
         .ourwork-down-studio-img {
            margin-left: 30px;
         }
      }
      .prime-03 {
         @media (max-device-width: 1180px) {
            margin-top: 50px;
         } 
         @media (max-device-width: 780px) {
            margin-top: 100px;
         } 
      }
      .prime-02 {
         @media (max-device-width: 1180px) {
            margin-top: -250px;
            float: right;
            margin-right: 20px;
         } 
         @media (max-device-width: 780px) {
            margin-top: -240px;
         } 
      }
      .prime-04 {
         @media (max-device-width: 1180px) {
            margin-top: -40px;
            float: right;
            margin-right: 20px;
         } 
         @media (max-device-width: 780px) {
            margin-top: -20px;
         } 
      }

 // - - - общий блок с линиями
      .ourwork-down-lines{
         width: 100%;
         height: 70px;
         background: none;
         float: left;
         @media (max-device-width: 1180px) {
            display: none;
         }
         
      }
         .ourwork-down-line-01 {
            width: 3px;
            height: 260px;
            transform: rotate(-60deg);
            border-left: 1px dashed $colspan;
            position: relative;
            float: left;
            background: none;
            margin-left: 330px;
            margin-top: -95px;
         }
         .ourwork-down-line-02 {
            width: 3px;
            height: 165px;
            transform: rotate(45deg);
            border-left: 1px dotted $colspan;
            position: relative;
            float: left;
            background: none;
            margin-left: 285px;
            margin-top: -35px;
         }
         .ourwork-down-line-03 {
            width: 3px;
            height: 280px;
            transform: rotate(-65deg);
            border-left: 1px dotted $colspan;
            position: relative;
            float: left;
            background: none;
            margin-left: 265px;
            margin-top: -100px;
         }

         //анимация движения текста
         @keyframes titleAnimationtxt {
            0% {
               transform: translateY(-50px);
               opacity: 0;
               -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
               clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 100%);
            }
            20% {
               transform: translateY(0);
               opacity: 1;
               -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0%);
               clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0%);
            }
            80% {
                  transform: translateY(0);
                  opacity: 1;
               -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0%);
               clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0%);
            }
            100% {
                  transform: translateY(50px);
                  opacity: 0;
                  -webkit-clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
                  clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
               
            }
         }
         .prime-01 .ourwork-down-studio-txt {
            animation-delay: 0s;
         }
         .prime-02 .ourwork-down-studio-txt {
            animation-delay: 0.3s;
         }
         .prime-03 .ourwork-down-studio-txt {
            animation-delay: 0.6s;
         }
         .prime-04 .ourwork-down-studio-txt {
            animation-delay: 0.9s;
         }

