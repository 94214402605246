$colred: #F33131;
$colblue: #6ACCF9;
$colyell: #FED176;
$colgreen: #66E69A;
$coltxt: #393939;
$colspan: #243976;

.primer {
   width: 100%;
   height: 720px;
   background: none;
   position: relative;
   float: left;
   @media (max-device-width: 1280px) {
      height: 660px;
   } 
   @media (max-device-width: 780px) {
      height: 600px;
   } 
}
   .primer-center {
      width: 1280px;
      min-width: 1280px;
      height: 100%;
      margin: auto;
      background: none;
      @media (max-device-width: 1280px) {
         width: 780px;
         min-width: 780px;
      } 
      @media (max-device-width: 780px) {
         width: 480px;
         min-width: 480px;
      } 
   }

// - - - верхний блок с названием и котом
      .primer-top {
         width: 100%;
         height: 50px;
         background: none;
         position: relative;
         float: left;
      }
         .primer-top-span {
            width: 300px;
            height: 50px;
            background: $colred;
            border-radius: 25px;
            margin-left: 130px;
            float: left;
            & p {
               font-size: 1.5em;
               line-height: 50px;
               width: 100%;
               color: #fff;
               letter-spacing: 1px;
               transition: 0.3s ease;
               @media (max-device-width: 780px) {
                  line-height: 45px;
                  font-size: 1.4em;
               }
               &:hover {
                  letter-spacing: 2.5px;
                  transition: 0.3s ease;
               } 
            }
            @media (max-device-width: 1280px) {
               margin-left: 20px;
            } 
            @media (max-device-width: 780px) {
               float: right;
               height: 45px;
               width: 270px;
            } 
            @media (max-device-width: 480px) {
               border-radius: 25px 0 0 25px;
            } 
         }
         .primer-top-img {
            width: 200px;
            height: 200px;
            margin-top: -20px;
            float: right;
            margin-right: 100px;
            background: none;
            @media (max-device-width: 1280px) {
               margin-right: 20px;
            } 
            @media (max-device-width: 780px) {
               float: left;
               margin-top: -60px;
               width: 150px;
               transform: scaleX(-1);
               margin-left: 20px;
            } 
         }

// нижний блок с примерами
      .primer-down {
         width: 100%;
         height: 600px;
         background: none;
         margin-top: 10px;
         position: relative;
         float: left;
         @media (max-device-width: 780px) {
            height: 500px;
         } 
      }
// - - - блок с вертушкой
      .primer-down-prim {
         width: 900px;
         height: 570px;
         margin-left: 20px;
         margin-top: 40px;
         background: none;
         float: left;
         position: relative;
         @media (max-device-width: 1280px) {
            width: 650px;
            min-width: 650px;
            margin-left: 0px;
            height: 550px;
         } 
         @media (max-device-width: 780px) {
            width: 400px;
            min-width: 400px;
            margin-left: 0px;
            height: 470px;
         } 
      }
         .primer-down-prim-01 {
            width: 200px;
            height: 400px;
            float: left;
            position: relative;
            background: none;
            animation-name: titleAnimation7;
            animation-duration: 1s;
            @media (max-device-width: 1280px) {
               width: 100px;
            } 
            @media (max-device-width: 780px) {
               width: 20px;
            } 
            &.pr-01 {
               margin-left: 20px;
               transition: 1s;
               @media (max-device-width: 780px) {
                  margin-left: 0px;
               } 
            }
            &.pr-02 {
               margin-left: 20px;
               transition: 1s;
               margin-top: 50px;
            }
            &.pr-03 {
               margin-left: 20px;
               transition: 1s;
               margin-top: 100px;
            }
            &.pr-01 {
               z-index: 1;
            }
            &.pr-02 {
               z-index: 2;
            }
            &.pr-03 {
               z-index: 3;
            }
         }
         .primer-down-prim-02 {
            width: 200px;
            height: 400px;
            float: left;
            margin-left: 20px;
            background: none;
            position: relative;
            animation-name: titleAnimation6;
            animation-duration: 1s;
            @media (max-device-width: 1280px) {
               width: 100px;
               margin-left: 0px;
            } 
            @media (max-device-width: 780px) {
               width: 20px;
            } 
            &.pr-01 {
               margin-left: 240px;
               transition: 1s;
               margin-top: 50px;
               @media (max-device-width: 1280px) {
                  margin-left: 140px;
               }
               @media (max-device-width: 780px) {
                  margin-left: 40px;
               } 
            }
            &.pr-02 {
               margin-left: 20px;
               transition: 1s;
               margin-top: 100px;
            }
            &.pr-03 {
               margin-left: -640px;
               transition: 1.5s;
               @media (max-device-width: 1280px) {
                  margin-left: -340px;
               } 
               @media (max-device-width: 780px) {
                  margin-left: -100px;
               } 
            }
            &.pr-01 {
               z-index: 2;
            }
            &.pr-02 {
               z-index: 3;
            }
            &.pr-03 {
               z-index: 1;
            }
         }
         .primer-down-prim-03 {
            width: 200px;
            height: 400px;
            float: left;
            margin-left: 20px;
            background: none;
            position: relative;
            animation-name: titleAnimation5;
            animation-duration: 1s;
            @media (max-device-width: 1280px) {
               width: 100px;
            } 
            @media (max-device-width: 780px) {
               width: 20px;
            } 
            &.pr-01 {
               margin-left: 460px;
               transition: 1s;
               margin-top: 100px;
               @media (max-device-width: 1280px) {
                  margin-left: 260px;
               } 
               @media (max-device-width: 780px) {
                  margin-left: 80px;
               } 
            }
            &.pr-02 {
               margin-left: -640px;
               transition: 1.5s;
               margin-top: 0px;
               @media (max-device-width: 1280px) {
                  margin-left: -360px;
               } 
               @media (max-device-width: 780px) {
                  margin-left: -100px;
               } 
            }
            &.pr-03 {
               margin-left: -440px;
               transition: 1.5s;
               margin-top: 50px;
               @media (max-device-width: 1280px) {
                  margin-left: -240px;
               } 
               @media (max-device-width: 780px) {
                  margin-left: -60px;
               } 
            }
            &.pr-01 {
               z-index: 3;
            }
            &.pr-02 {
               z-index: 1;
            }
            &.pr-03 {
               z-index: 2;
            }
         }

// - - - стили для стерлочек
         .primer-down-str {
            width: 100px;
            height: 100px;
            background:  none;
            float: left;
            margin-left: 20px;
            margin-top: 230px;
            opacity: 0.5;
            @media (max-device-width: 1280px) {
               width: 50px;
               margin-left: 0px;
               margin-top: 260px;
            } 
            @media (max-device-width: 780px) {
               width: 38px;
               margin-left: 0px;
               margin-top: 160px;
               height: 200px;
            } 
            & img {
               background: none;
               @media (max-device-width: 780px) {
                  margin-top: 80px;
               } 
            }
            &:hover {
               opacity: 1;
               cursor: pointer;
            }
         }
         .left-str {
            margin-left: 100px;
            @media (max-device-width: 1280px) {
               margin-left: 10px;
            } 
            @media (max-device-width: 780px) {
               margin-left: 0px;
            } 

         }


// - - - - стили для картинок с вертушкой
         .primer-down-prim-col {
            width: 400px;
            height: 440px;
            background: none;
            float: left;
            border-radius: 30px;
            @media (max-device-width: 1280px) {
               width: 350px;
               height: 385px;
            } 
            @media (max-device-width: 780px) {
               width: 300px;
               height: 330px;
            } 
            &.col-blue {
               background: $colblue;
            }
            &.col-yell{
               background: $colyell;
            }
            &.col-green {
               background: $colgreen;
            }
         }
         .primer-down-prim-img {
            width: 400px;
            height: 440px;
            background: none;
            float: left;
            margin-left: 20px;
            margin-top: -420px;
            border-radius: 30px;
            -webkit-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
            -moz-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
            box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
            & img{
               border-radius: 30px;
            }
            @media (max-device-width: 1280px) {
               width: 350px;
               height: 385px;
               margin-left: 16px;
               margin-top: -370px;
            } 
            @media (max-device-width: 780px) {
               width: 300px;
               height: 330px;
               margin-left: 15px;
               margin-top: -315px;
            } 
         }


         //анимация движения вылетающей статьи
         @keyframes titleAnimation5 {
            0% {
               opacity: 1;
            }
            50% {
               opacity: 0.7;
         }
            100% {
                  opacity: 1;
            }
         }
         @keyframes titleAnimation6 {
            0% {
               opacity: 1;
            }
            50% {
               opacity: 0.7;
         }
            100% {
                  opacity: 1;
            }
         }
         @keyframes titleAnimation7 {
            0% {
               opacity: 1;
            }
            50% {
               opacity: 0.7;
         }
            100% {
                  opacity: 1;
            }
         }




