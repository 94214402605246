$colred: #F33131;
$colblue: #6ACCF9;
$colyell: #FED176;
$colgreen: #66E69A;
$coltxt: #393939;
$colspan: #243976;

.blogstatya {
   width: 100%;
   height: auto;
   background: none;
   position: relative;
   float: left;
      margin-bottom: -40px;
   // @media (max-device-width: 1280px) {
   //    height: 80px;
   // } 
   // @media (max-device-width: 900px) {
   //    height: 230px;
   // } 
}
   .blogstatya-center{
      width: 70%;
      height: 100%;
      min-width: 1080px;
      margin-left: 15%;
      background: none;
      position: relative;
      border-left: 1px dashed black;
      border-right: 1px dashed black;
      float: left;
      margin-bottom: 50px;
      @media (max-device-width: 1400px) {
         margin-left: 10%;
      } 
      @media (max-device-width: 1280px) {
         margin-left: 5%;
         min-width: 1080px;
      } 
      @media (max-device-width: 1180px) {
         margin-left: 2%;
         min-width: 1080px;
      } 
      @media (max-device-width: 1100px) {
         margin-left: 2%;
         min-width: 1000px;
      } 
      @media (max-device-width: 1040px) {
         margin-left: 4%;
         min-width: 900px;
      } 
      @media (max-device-width: 980px) {
         margin-left: 2%;
         min-width: 900px;
      } 
      @media (max-device-width: 940px) {
         margin-left: 4%;
         min-width: 800px;
      } 
      @media (max-device-width: 880px) {
         margin-left: 2%;
         min-width: 800px;
      } 
      @media (max-device-width: 840px) {
         margin-left: 5%;
         min-width: 700px;
      } 
      @media (max-device-width: 780px) {
         margin-left: 2%;
         min-width: 700px;
         margin-top: 20px;
      } 
      @media (max-device-width: 740px) {
         margin-left: 5%;
         min-width: 600px;
      } 
      @media (max-device-width: 680px) {
         margin-left: 2%;
         min-width: 600px;
      } 
      @media (max-device-width: 640px) {
         margin-left: 5%;
         min-width: 500px;
      } 
      @media (max-device-width: 580px) {
         margin-left: 2%;
         min-width: 500px;
      } 
      @media (max-device-width: 520px) {
         margin-left: 2%;
         min-width: 460px;
      } 
   }

   .container-st {
      width: 90%;
      margin-left: 5%;
      background: none;
      position: relative;
      float: left;

      & span {
         width: 100%;
         font-size: 2em;
         margin-top: 30px;
         margin-bottom: 0px;
      }
      & li {
         width: 95%;
         margin-left: 3%;
         text-align: left;
         margin-top: 10px;
         font-size: 1.1em;
      }
      & p.sp-01 {
         width: 100%;
         text-align: left;
         margin-top: 20px;
         font-size: 1.1em;
      }
      & p.sp-02 {
         width: 50%;
         text-align: left;
         margin-top: 30px;
         font-size: 1.3em;
         font-weight: 500;
         border-bottom: 1px dashed #393939;
         @media (max-device-width: 1040px) {
            width: 60%;
         } 
         @media (max-device-width: 940px) {
            width: 70%;
         } 
         @media (max-device-width: 740px) {
            width: 90%;
         } 
      }
      & p.sp-03 {
         width: 70%;
         text-align: right;
         margin-top: 20px;
         font-size: 1.1em;
         background: $coltxt;
         color: #fff;
         padding: 10px;
         float: right;
         @media (max-device-width: 740px) {
            width: 80%;
         } 
      }

      & p.sp-04 {
         width: 92%;
         text-align: justify;
         margin-top: 20px;
         font-size: 1em;
         border-top: 1px dashed $coltxt;
         border-bottom: 1px dashed $coltxt;
         padding: 30px;
         font-style: oblique;
         @media (max-device-width: 740px) {
            padding: 15px;
         } 
      }
      & p.sp-05 {
         width: 80%;
         text-align: left;
         margin-top: 10px;
         font-size: 1em;
         background: $coltxt;
         color: #fff;
         padding: 10px;
      }
      & img {
         margin-top: 20px;
      }
      & .icon {
         margin-top: 0px;
      }
   }

   .st-top-menu {
      width: 100%;
      height: 30px;
      background: none;
      position: relative;
      float: left;
      margin-top: 20px;
      .st-top-menu-left {
         width: 200px;
         height: 100%;
         background: none;
         position: relative;
         float: left;
         border: 1px dashed rgb(2, 2, 79);
         border-radius: 10px;
         cursor: pointer;
         transition: 0.2s;
         & img {
            width: 30px;
            height: 30px;
            margin-left: -30px;
         }
         &:hover {
            border: 1px dashed #fff;
            transition: 0.2s;
            width: 220px;
            p {
               text-decoration: underline #808080;
               letter-spacing: 1px;
               transition: 0.2s;
            }
         }
         & p {
            transition: 0.2s;
         }
      }
      .st-top-menu-right {
         width: 200px;
         height: 100%;
         background: none;
         position: relative;
         float: right;
         border: 1px dashed rgb(2, 2, 79);
         border-radius: 10px;
         cursor: pointer;
         transition: 0.2s;
         & img {
            width: 30px;
            height: 30px;
            margin-right: -30px;
         }
         &:hover {
            border: 1px dashed #fff;
            transition: 0.2s;
            p {
               text-decoration: underline #808080;
               letter-spacing: 1px;
               transition: 0.2s;
            }
         }
         & p {
            transition: 0.2s;
         }
      }
      & p {
         margin-top: 0px;
         width: 100%;
         text-align: center;
         line-height: 30px;
      }

   }



   .blogspisok-center-01 {
      width: 1280px;
      height: 100%;
      margin: auto;
      background: none;
      position: relative;
      display: grid;
      // grid-template: repeat(3, 400px) / repeat(3, 400px);
      grid-template-columns: 400px 400px;
      justify-content: center;
      column-gap: 80px;
      margin-bottom: 30px;
      @media (max-device-width: 1280px) {
         width: 1180px;
         min-width: 1180px;
         grid-template-columns: 400px 400px;
      } 
      @media (max-device-width: 1180px) {
         width: 1080px;
         min-width: 1080px;
      } 
      @media (max-device-width: 1080px) {
         width: 900px;
         min-width: 900px;
      } 
      @media (max-device-width: 900px) {
         width: 780px;
         min-width: 780px;
         grid-template-columns: 400px;
      } 
      @media (max-device-width: 780px) {
         width: 480px;
         min-width: 480px;
         row-gap: 20px;
      } 

      .blogspisok-statya-01 {
         width: 100%;
         height: 100%;
         border: 1px dashed #393939;
         border-radius: 10px;
         // -webkit-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
         // -moz-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
         // box-shadow: -2px 3px 5px 0px rgba(0,0,0,0.2);
         transition: 0.2s;
         &:hover {
            -webkit-box-shadow: -2px -3px 5px 0px rgba(0,0,0,0.2), 2px 3px 5px 0px rgba(0,0,0,0.2);;
            -moz-box-shadow: -2px -3px 5px 0px rgba(0,0,0,0.2), 2px 3px 5px 0px rgba(0,0,0,0.2);;
            box-shadow: -2px -3px 5px 0px rgba(0,0,0,0.2), 2px 3px 5px 0px rgba(0,0,0,0.2);;
            transition: 0.2s;
         }
         & .blogspisok-statya-img {
            width: 370px;
            height: 250px;
            margin: auto;
            margin-top: 10px;
            background: none;
            & img{
               height: 100%;
               border-radius: 6px;
            }
         }
         & .blogspisok-statya-text {
            width: 370px;
            height: 120px;
            margin: auto;
            margin-top: 10px;
            background: none;
            .statya-text-span {
               width: 100%;
               height: 30px;
               background: none;
               float: left;
               & p{
                  font-weight: 600;
                  font-size: 1.1em;
               }
            }
            .statya-text-opis {
               width: 100%;
               height: 60px;
               background: none;
               float: left;
               & p{
                  font-weight: 300;
                  font-size: 1em;
                  text-align: left;
               }
            }
            .statya-text-snoska {
               width: 100%;
               height: 30px;
               background: none;
               float: left;
               .statya-text-snoska-hesh {
                  width: 60%;
                  height: 100%;
                  background: none;
                  float: left;
                  margin-top: 2px;
                  & p{
                     font-weight: 300;
                     font-size: 0.9em;
                     text-align: left;
                     color: $colspan;
                  }
               }
               .statya-text-snoska-ssil {
                  width: 38%;
                  height: 35px;
                  background: none;
                  float: left;
                  // border: 1px dashed $coltxt;
                  border-radius: 10px;
                  margin-top: -7px;
                  cursor: pointer;
                  transition: 0.2s all;
                  &:hover {
                     background: $colgreen;
                     & p {
                        color: #fff;
                        transition: 0.1s all;
                     }
                     transition: 0.2s all;
                  }
                  & p{
                     line-height: 35px;
                     width: 100%;
                  }
               }
            }
         }
      }
   }