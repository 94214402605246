$colred: #F33131;
$colblue: #6ACCF9;
$colyell: #FED176;
$colgreen: #66E69A;
$coltxt: #393939;
$colspan: #243976;

footer {
   width: 100%;
   height: 505px;
   background: none;
   position: relative;
   float: left;
   @media (max-device-width: 1180px) {
      height: 700px;
   }
   @media (max-device-width: 780px) {
      height: 870px;
   }
   
}

// - - - - стиль зеленого футера
   .footer-main {
      width: 100%;
      height: 360px;
      margin-top: 90px;
      background: $colgreen;
      @media (max-device-width: 1180px) {
         margin-top: 305px;
      }
      @media (max-device-width: 780px) {
         margin-top: 455px;
      }
   }

   .footer-main-center {
      width: 1180px;
      min-width: 1180px;
      height: 360px;
      position: relative;
      background: none;
      margin: auto;
      @media (max-device-width: 1180px) {
         width: 1080px;
         min-width: 1080px;
      } 
      @media (max-device-width: 1080px) {
         width: 780px;
         min-width: 780px;
      } 
      @media (max-device-width: 780px) {
         width: 480px;
         min-width: 480px;
      }
   }
// - - - - стиль левой части футера
      .footer-main-blockleft {
         width: 700px;
         height: 100%;
         background: none;
         float: left;
         position: relative;
         @media (max-device-width: 780px) {
            width: 480px;
         }
      }
         .footer-main-blocklogo {
            width: 700px;
            height: 200px;
            margin-top: -30px;
            background: none;
            float: left;
            position: relative;
            @media (max-device-width: 780px) {
               width: 480px;
            } 
         }


// - - - - - стиль синий кружок
            .blocklogo-round {
               width: 200px;
               height: 200px;
               background: $colblue;
               float: left;
               position: relative;
               border-radius: 100px;
               margin-left: 60px;
               @media (max-device-width: 1180px) {
                  margin-top: 100px;
               } 
               @media (max-device-width: 780px) {
                  margin-left: 10px;
                  margin-top: 50px;
               } 
            }

// - - - - - стиль логотип и надпись
            .blocklogo-text {
               width: 400px;
               height: 160px;
               background: none;
               float: left;
               position: relative;
               margin-left: -120px;
               margin-top: 50px;
               @media (max-device-width: 1180px) {
                  margin-top: 170px;
               } 
               @media (max-device-width: 780px) {
                  margin-top: 100px;
                  width: 350px;
               } 
               span {
                  font-size: 3em;
                  width: 100%;
                  color: $colred;
                  text-align: left;
               }
               p {
                  font-size: 2em;
                  color: $colspan;
                  width: 100%;
                  text-align: left;
                  font-family: 'Comfortaa', sans-serif;
               }
            }

// - - - - - стиль кота и номера телефона
         .footer-main-blockcontact {
            width: 700px;
            height: 160px;
            margin-top: 30px;
            background: none;
            float: left;
            position: relative;
            @media (max-device-width: 780px) {
               width: 480px;
            } 
         }
            .blockcontact-img {
               width: 200px;
               height: 200px;
               margin-top: 10px;
               margin-left: 20px;
               float: left;
               position: relative;
               background: none;
               transform: scaleX(-1);
               @media (max-device-width: 1180px) {
                  margin-top: -400px;
                  margin-left: 50px;
               } 
               @media (max-device-width: 780px) {
                  width: 160px;
                  height: 160px;
                  margin-top: -650px;
                  margin-left: 270px;
                  z-index: 3;
               } 
               
            }
            .blockcontact-text {
               width: 300px;
               height: 80px;
               background: none;
               float: left;
               position: relative;
               margin-top: 60px;
               margin-left: 100px;
               @media (max-device-width: 1180px) {
                  margin-left: 450px;
               } 
               @media (max-device-width: 780px) {
                  margin-left: 150px;
               } 
            }
            
               .blockcontact-text-top {
                  width: 300px;
                  height: 40px;
                  background: none;
                  float: left;
                  position: relative;
                  .blockcontact-text-top-img {
                     width: 40px;
                     height: 40px;
                     float: left;
                     background: none;
                     
                  }
                  .blockcontact-text-top-txt {
                     width: 250px;
                     height: 40px;
                     float: left;
                     background: none;
                     margin-left: 10px;
                     p {
                        font-size: 1.3em;
                        line-height: 40px;
                        color: #fff;
                        transition: 0.5s;
                     }

                  }
                  .blockcontact-text-top-txt:hover {
                     border-bottom: 1px dotted #fff;
                     p {
                        letter-spacing: 1px;
                        transition: 0.5s;
                     }
                  }
               }

// - - - - стиль правой части футера
      .footer-main-blockright {
         width: 480px;
         height: 100%;
         background: none;
         float: left;
         position: relative;
         @media (max-device-width: 1180px) {
            margin-top: -570px;
            margin-right: 0px;
            width: 450px;
            float: right;
         } 
         @media (max-device-width: 780px) {
            margin-top: -720px;
         }
      }
// - - - - блок с телефона, вотсап
         .blockright-tel {
            width: 50px;
            height: 230px;
            background: none;
            float: left;
            position: relative;
         }
            .blockright-tel-img {
               width: 35px;
               height: 35px;
               background: none;
               float: left;
               position: relative;
               margin-left: 8px;
               margin-top: 9px;
               transition: 0.2s;
               & img {
                  -webkit-filter: drop-shadow(2px 3px 3px rgba(0,0,0,0.5));
                  filter: drop-shadow(2px 3px 3px rgba(0,0,0,0.5));
               }
            }
            .blockright-tel-img:hover {
               width: 40px;
               height: 40px;
               margin-left: 5.5px;
               margin-top: 6.5px;
               transition: 0.2s;
            }

// - - - - блок с меню
         .blockright-menu {
            width: 320px;
            height: 400px;
            background: $colyell;
            float: left;
            position: relative;
            margin-top: -70px;
            border-radius: 30px;
            -webkit-box-shadow: 3px 5px 5px 0px rgba(0,0,0,0.3);
            -moz-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.3);
            box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.3);
         }
            .blockright-menu-sp {
               width: 280px;
               height: 50px;
               background: none;
               float: left;
               margin-top: 20px;
               margin-left: 20px;
               p {
                  width: 100%;
                  font-size: 1.5em;
                  color: $colspan;
                  text-align: left;
                  line-height: 50px;
                  letter-spacing: 2px;
                  transition: 0.5s;
               }
               p:hover {
                  text-decoration: underline dotted;
                  letter-spacing: 4px;
                  transition: 0.5s;
               }
            }
            .blockright-menu-txt {
               width: 280px;
               height: 200px;
               background: none;
               float: left;
               margin-top: 20px;
               margin-left: 20px;
               p {
                  width: 100%;
                  line-height: 30px;
                  font-size: 1.2em;
                  color: $colspan;
                  text-align: left;
                  transition: 0.5s;
               }
               p:hover {
                  border-bottom: 1px dotted #fff;
                  letter-spacing: 2px;
                  transition: 0.5s;
               }
            }
// - - - - блок с кнопкой в меню
            .blockright-menu-but {
               width: 330px;
               height: 80px;
               background: $colred;
               float: left;
               margin-top: 10px;
               margin-left: -60px;
               border-radius: 20px;
               -webkit-box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.3);
               -moz-box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.3);
               box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.3);
               p {
                  width: 100%;
                  font-size: 1.5em;
                  color: #fff;
                  text-align: center;
                  line-height: 80px;
                  font-weight: 300;
               }
               p:hover {
                  text-decoration: underline dotted;
                  letter-spacing: 2px;
                  transition: 0.5s;
               }
            }

// - - - - блок с социальными сетями
         .blockright-soc {
            width: 50px;
            height: 250px;
            background: none;
            float: left;
            position: relative;
            margin-left: -20px;
            margin-top: 100px;
         }
         .blockright-soc-img {
            width: 50px;
            height: 50px;
            background: none;
            float: left;
            position: relative;
            margin-left: 0px;
            margin-top: 10px;
            & img {
               -webkit-filter: drop-shadow(2px 3px 3px rgba(0,0,0,0.5));
               filter: drop-shadow(2px 3px 3px rgba(0,0,0,0.5));
               transition: 0.2s;
            }
            & img:hover {
               -webkit-filter: drop-shadow(2px 3px 3px rgba(255,255,255,0.5));
               filter: drop-shadow(2px 3px 3px rgba(255, 255, 255, 0.5));
               transition: 0.2s;
            }
         }

// - - - - подппись под футером
   .footer-span {
      width: 100%;
      height: 45px;
      margin-top: 10px;
      background: none;
      p {
         width: 100%;
         font-size: 0.8em;
         text-align: center;
      }
   }