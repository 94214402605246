$colred: #F33131;
$colblue: #6ACCF9;
$colyell: #FED176;
$colgreen: #66E69A;
$coltxt: #393939;
$colspan: #243976;

.kamorka {
   width: 100%;
   height: 520px;
   background: none;
   position: relative;
   float: left;
   @media (max-device-width: 1280px) {
      height: 900px;
   } 
   @media (max-device-width: 780px) {
      height: 800px;
   } 
}
   .kamorka-center {
      width: 1280px;
      min-width: 1280px;
      height: 100%;
      margin: auto;
      background: none;
      @media (max-device-width: 1280px) {
         width: 780px;
         min-width: 780px;
      } 
      @media (max-device-width: 780px) {
         width: 480px;
         min-width: 480px;
      } 
   }


// - - - - верхний блок с названием
      .kamorka-top {
         width: 100%;
         height: 50px;
         background: none;
         position: relative;
      }
      //блок с линией
         .kamorka-top-line {
            width: 330px;
            height: 30px;
            margin-left: 200px;
            margin-top: 15px;
            border-top: 1px dotted $colspan;
            float: left;
            background: none;
            @media (max-device-width: 1280px) {
               margin-left: 100px;
               width: 200px;
            } 
            @media (max-device-width: 780px) {
               width: 30px;
               margin-left: 60px;
            } 
         }
         //блок с названиями
         .kamorka-top-span {
            width: 375px;
            height: 50px;
            float: left;
            background: none;
            margin-left: 10px;
            @media (max-device-width: 780px) {
               width: 350px;
            } 
            & p {
               font-size: 1.5em;
               font-weight: 600;
               width: 100%;
               text-align: left;
               color: #243976;
               line-height: 30px;
               letter-spacing: 1px;
               @media (max-device-width: 780px) {
                  font-size: 1.4em;
                  letter-spacing: 0.5px;
               } 
            }
            & span {
               font-size: 1.5em;
               font-weight: 600;
               width: 100%;
               text-align: right;
               color: $colred;
               line-height: 20px;
            }
         }

// - - - - нижний блок с названием
      .kamorka-down {
         width: 100%;
         height: 470px;
         background: none;
         position: relative;
         @media (max-device-width: 1180px) {
            height: 420px;
         }
         @media (max-device-width: 780px) {
            height: 460px;
         } 
      }
         //блок с линийми и плюсами
         .kamorka-down-plus {
            width: 42px;
            height: 100%;
            float: left;
            margin-left: 180px;
            margin-top: -35px;
            background: none;
            @media (max-device-width: 1280px) {
               margin-left: 80px;
            } 
            @media (max-device-width: 780px) {
               margin-left: 40px;
            } 
            .kamorka-down-plus-line {
               width: 20px;
               border-right: 1px dotted $colspan;
               background: none;
            }
            .li01 {
               height: 65px;
               @media (max-device-width: 780px) {
                  height: 66px;
               } 
            }
            .li02 {
               height: 26px;
               @media (max-device-width: 780px) {
                  height: 30px;
               }
            }
            .kamorka-down-plus-block {
               width: 40px;
               height: 40px;
               border: 1px dotted $colspan;
               background: none;
               & p {
                  font-size: 3em;
                  font-weight: 200;
                  color: $colspan;
                  width: 100%;
                  line-height: 36px;
                  opacity: 0.8;
                  transition: 0.2s;
                  &:hover {
                     font-size: 3.5em;
                     transition: 0.2s;
                     cursor: pointer;
                  }
               }
            }
         }
         //блок с навзаниями статей
         .kamorka-down-staty {
            width: 500px;
            height: 100%;
            float: left;
            margin-left: 20px;
            background: none;
            @media (max-device-width: 780px) {
               width: 360px;
            } 
            & p {
               font-family: 'Comfortaa', sans-serif;
               font-size: 1.1em;
               width: 100%;
               text-align: left;
               margin-top: 30px;
               transition: 0.5s;
               cursor: pointer;
               &:hover {
                  letter-spacing: 0.5px;
                  transition: 0.5s;
               }
               @media (max-device-width: 780px) {
                  font-size: 1em;
               } 
            }
            @media (max-device-width: 780px) {
               #st-pon {
                  height: 42px;
               }
            } 
         }
         //блок с картинкой
         .kamorka-down-logo {
            width: 500px;
            height: 100%;
            float: left;
            margin-left: 20px;
            background: none;
            @media (max-device-width: 780px) {
               width: 420px;
            } 
            .kamorka-down-logo-img {
               width: 600px;
               height: 450px;
               background: none;
               @media (max-device-width: 1450px) {
                  width: 500px;
               } 
               @media (max-device-width: 1180px) {
                  margin-left: 50px;
                  float: none;
                  width: 600px;
               }
               @media (max-device-width: 780px) {
                  width: 400px;
                  margin-left: 20px;
                  height: 350px;
               } 
            }
         }
// - - - - блок вылетающей статьи
      .kamorka-block-st {
         width: 900px;
         height: 300px;
         background: #fff;
         position: absolute;
         margin-top: -450px;
         margin-left: 240px;
         z-index: 3;
         animation-name: titleAnimation4;
         animation-timing-function:ease;
         animation-duration: 1s;
         -webkit-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
         -moz-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
         box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.5);
         @media (max-device-width: 1280px) {
            width: 700px;
            margin-left: 40px;
            height: 370px;
         } 
         @media (max-device-width: 780px) {
            width: 400px;
            margin-left: 50px;
            height: 680px;
            margin-top: -520px;
         } 
      }
         //анимация движения вылетающей статьи
         @keyframes titleAnimation4 {
            0% {
               transform: translateY(400px);
               opacity: 0;
            }
            100% {
                  transform: translateY(0px);
                  opacity: 1;
               
            }
         }
         .block-st-left {
            width: 300px;
            height: 300px;
            float: left;
            background: none;
            @media (max-device-width: 780px) {
               width: 400px;
            } 
         }
            .block-st-left-img {
               width: 260px;
               height: 260px;
               margin-left: 20px;
               margin-top: 20px;
               float: left;
               background: none;
               @media (max-device-width: 780px) {
                  margin-left: 70px;
               } 
            }
            
         .block-st-right {
            width: 600px;
            height: 300px;
            float: left;
            background: none;
            @media (max-device-width: 1280px) {
               width: 400px;
               height: 350px;
            } 
            @media (max-device-width: 780px) {
               width: 400px;
               margin-left: 0px;
               height: 330px;
            } 
            .block-st-right-txt {
               width: 560px;
               height: 220px;
               margin-top: 20px;
               margin-left: 20px;
               float: left;
               background: none;
               @media (max-device-width: 1280px) {
                  width: 380px;
                  margin-left: 0px;
                  height: 300px;
               } 
               @media (max-device-width: 780px) {
                  width: 360px;
                  margin-left: 20px;
                  float: none;
                  margin-top: 0px;
                  height: 330px;
               } 
               & span {
                  font-size: 1.3em;
                  font-weight: 600;
                  width: 100%;
                  text-align: left;
                  margin-bottom: 10px;
                  @media (max-device-width: 1280px) {
                     text-align: center;
                     font-size: 1.1em;
                  }
               }
               & p {
                  font-size: 1em;
                  font-weight: 300;
                  width: 100%;
                  text-align: justify;
               }
            }
            .block-st-right-ssil {
               width: 560px;
               height: 40px;
               float: left;
               background: none;
               margin-left: 20px;
               @media (max-device-width: 1280px) {
                  width: 380px;
                  margin-left: 0px;
               } 
               @media (max-device-width: 780px) {
                  width: 360px;
                  margin-left: 20px;
               } 
               .block-st-right-ssil-01 {
                  width: 200px;
                  height: 40px;
                  float: left;
                  background: none;
                  & p {
                     font-size: 1.1em;
                     width: 100%;
                     text-align: left;
                     line-height: 40px;
                     transition: 0.3s;
                     &:hover {
                        letter-spacing: 2px;
                        transition: 0.3s;
                        cursor: pointer;
                     }
                  }
               }
               .block-st-right-ssil-02 {
                  width: 100px;
                  height: 40px;
                  float: right;
                  background: none;
                  & p {
                     font-size: 1em;
                     width: 100%;
                     text-align: right;
                     line-height: 40px;
                     &:hover {
                        text-decoration: underline dotted $coltxt;
                        cursor: pointer;
                        text-underline-offset: 2px;
                     }
                  }
               }
            }
         }
