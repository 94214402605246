$colred: #F33131;
$colblue: #6ACCF9;
$colyell: #FED176;
$colgreen: #66E69A;
$coltxt: #393939;
$colspan: #243976;

.blogsocial {
   width: 100%;
   height: 540px;
   background: url(../../../public/fon/fon_04.jpg);
   background-origin: content-box;
   background-size: cover;
   background-position: center;
   position: relative;
   float: left;
   margin-bottom: 30px;
   @media (max-device-width: 780px) {
      background-position: left;
   } 
}
   .blogsocial-center {
      width: 1280px;
      min-width: 1280px;
      height: 100%;
      margin: auto;
      background: none;
      @media (max-device-width: 1280px) {
         width: 1180px;
         min-width: 1180px;
      } 
      @media (max-device-width: 1180px) {
         width: 1080px;
         min-width: 1080px;
      } 
      @media (max-device-width: 1080px) {
         width: 900px;
         min-width: 900px;
      } 
      @media (max-device-width: 900px) {
         width: 780px;
         min-width: 780px;
      } 
      @media (max-device-width: 780px) {
         width: 480px;
         min-width: 480px;
      } 
   }

   .blogsocial-container {
      width: 450px;
      height: 500px;
      margin-left: 80px;
      margin-top: 25px;
      background: none;
      position: relative;
      float: left;
      @media (max-device-width: 1180px) {
         margin-left: 20px;
      } 
      @media (max-device-width: 900px) {
         background: rgba(256, 256, 256, 0.5);
         padding: 10px;
         height: 480px;
      } 
      @media (max-device-width: 780px) {
         background: none;
         padding: 0;
      } 
      .blogsocial-cont {
         width: 100%;
         height: 120px;
         background: none;
         float: left;
         position: relative;

         .blogsocial-cont-top {
            width: 100%;
            height: 50px;
            position: relative;
            float: left;
            background: none;
            .blogsocial-cont-top-img {
               width: 55px;
               height: 55px;
               position: relative;
               float: left;
               background: none;
               border: 2px dashed #fff;
               border-radius: 30px;
               & img {
                  width: 35px;
                  margin-top: 10px;
                  margin-left: 10px;
               }
            }
            .blogsocial-cont-top-span {
               width: 350px;
               margin-left: 20px;
               height: 50px;
               position: relative;
               float: left;
               background: none;
               cursor: pointer;
               & p{
                  font-size: 2.5em;
                  color: #fff;
                  line-height: 50px;
                  font-weight: 300;
                  transition: 0.3s;
                  &:hover{
                     letter-spacing: 3px;
                     transition: 0.3s;
                  }
               }
            }
         }
         .blogsocial-cont-down {
            width: 350px;
            height: 80px;
            margin-left: 80px;
            position: relative;
            float: left;
            background: none;
            & p{
               width: 100%;
               text-align: left;
               line-height: 20px;
            }
         }
      }
   }
